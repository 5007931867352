@charset "UTF-8";
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b4b4b4;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b4b4b4;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b4b4b4;
  opacity: 1 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
.Main {
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  line-height: 1.42857143;
  text-size-adjust: 100%;
  min-height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.Main * {
  box-sizing: border-box;
}
.Main.is-max-height {
  height: 100%;
}
#__next {
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
#__next.is-max-height {
  height: 100%;
}
button {
  color: inherit;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}
button::-moz-focus-inner {
  border: 0;
}
svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.Page {
  color: #333333;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
}
.c24m-tap-bar {
  display: none;
}
.scroll-to-top-arrow {
  background-color: #0271c2;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 48px;
  padding: 20px 0px 0px 17px;
  position: fixed;
  z-index: 1000;
  bottom: 75px;
  right: 10px;
  width: 48px;
  animation: 0.2s ease 0s 1 normal none running;
}
.scroll-to-top-arrow.is-hidden {
  display: none;
}
.scroll-to-top-arrow.is-app {
  bottom: calc(75px + env(safe-area-inset-bottom));
}
.scroll-to-top-arrow.is-comparisonMode {
  bottom: 145px;
}
.scroll-to-top-arrow.is-app.is-comparisonMode {
  bottom: calc(115px + env(safe-area-inset-bottom));
}
.scroll-to-top-arrow:before {
  border: solid #fff;
  border-width: 2px 0 0 2px;
  content: " ";
  display: inline-block;
  height: 11px;
  transform: rotate(45deg);
  width: 11px;
}
select {
  color: #333333;
  background-color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  /*Removes default style Firefox*/
  text-indent: 0.01px;
}
.basicTransition-enter {
  opacity: 0.01;
}
.basicTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.basicTransition-leave {
  opacity: 1;
}
.basicTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.c24m-page-content {
  display: none;
}
.c24m-page {
  display: block;
}
.c24m-page .OverlayContainer.is-opened {
  overflow: hidden;
}
.c24m-footer {
  margin-top: auto;
  background-color: #ffffff;
}
.c24m-footer-content {
  padding-bottom: calc(75px + env(safe-area-inset-bottom));
}
.c24m-footer.is-sticky {
  position: absolute;
  bottom: 0;
}
.c24m-customer.c24m-customer-guest.is-hidden {
  display: none;
}
.c24m-customer.c24m-customer-user.is-hidden {
  display: none;
}
.c24m-customer.c24m-customer-expired.is-hidden {
  display: none;
}
#c24m-navi-customer-user.is-hidden,
#c24m-navi-customer-guest.is-hidden,
#c24m-navi-customer-expired.is-hidden {
  display: none;
}
#c24m-navi-salutation-user.is-hidden,
#c24m-navi-salutation-guest.is-hidden,
#c24m-navi-salutation-expired.is-hidden {
  display: none;
}
#c24m-kb-logout-navi-expired.is-hidden,
#c24m-kb-logout-navi-user.is-hidden {
  display: none;
}
.c24m-search.is-hidden {
  display: none;
}
.Wireframe-footerFirstInfo {
  font-size: 12px;
  line-height: 21px;
  cursor: pointer;
  display: inline-block;
}
.AppPromotion {
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  position: fixed;
  z-index: 1;
  bottom: 75px;
  display: flex;
}
.AppPromotion-button {
  background-color: #0271c2;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
  height: 40px;
  border: 1px solid #005ea8;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #ffffff;
}
.AppPromotion-labelContainer {
  border-right: 1px solid #005ea8;
  column-gap: 6px;
  display: flex;
  padding: 8px 16px;
}
.AppPromotion-labelContainer span {
  color: #ffffff;
  text-decoration: none;
}
.AppPromotion-DownloadIcon,
.AppPromotion-closeIcon {
  font-size: inherit;
  justify-content: center;
  overflow: hidden;
  vertical-align: initial;
  stroke-width: 0;
  display: inline-flex;
  fill: #ffffff;
}
.AppPromotion-DownloadIcon {
  width: 18px;
}
.AppPromotion-closeIcon {
  width: 12px;
}
.AppPromotion-closeIconContainer {
  align-items: center;
  display: flex;
  font-size: 12px;
  vertical-align: middle;
  padding: 8px 12px 8px 10px;
}
.AppSplashOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 112;
  background-size: cover;
  background-position: center;
}
.AppSplashOverlay-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
}
.AppSplashOverlay-descriptionContainer {
  position: absolute;
  bottom: 0;
  color: #ffffff;
  width: 100%;
}
.AppSplashOverlay-descriptionHeader {
  font-size: 20px;
  margin-bottom: 23px;
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .AppSplashOverlay-descriptionHeader {
    font-size: 18px;
  }
}
.AppSplashOverlay-description {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 50px;
}
@media screen and (max-height: 768px) {
  .AppSplashOverlay-description {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px), screen and (max-width: 320px) {
  .AppSplashOverlay-description {
    font-size: 20px;
  }
}
.AppSplashOverlay-ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AppSplashOverlay-downloadCta {
  width: 300px !important;
  margin-bottom: 18px;
}
.AppSplashOverlay-mobileStoreLabelsContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  width: 300px;
}
@media screen and (max-height: 768px) {
  .AppSplashOverlay-mobileStoreLabelsContainer {
    margin-bottom: 20px;
  }
}
.AppSplashOverlay-mobileStoreLabel {
  width: 120px;
}
.AppSplashOverlay-close {
  position: absolute;
  height: 60px;
  width: 60px;
  top: 0px;
  right: 0px;
  padding: 20px;
}
.AppSplashOverlay-iframe {
  position: absolute;
  visibility: hidden;
  height: 0;
  width: 0;
}
.BenefitAmountWarning {
  position: relative;
}
.BenefitAmountWarning-container {
  margin: 10px 0 20px 0;
  font-size: 14px;
  border: 1px solid #005ea8;
  border: 1px solid #333333;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
}
.BenefitAmountWarning-container:before {
  margin-right: 1px;
  position: absolute;
  top: -10px;
  left: 40px;
  height: 18px;
  width: 18px;
  background: #ffffff;
  content: "";
  border: 1px solid #333333;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
  z-index: 5;
}
.BenefitAmountWarning-adjustmentContainer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  color: #005ea8;
}
.BenefitAmountWarning-close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #b4b4b4;
}
.BenefitAmountWarning-content {
  padding-right: 15px;
}
.Button {
  display: block;
  width: 100%;
  background-color: #0271c2;
  padding: 10px;
  color: #ffffff;
  border-radius: 3px;
  font-size: 20px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: 1px solid #0271c2;
  pointer-events: auto;
  text-align: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
}
.Button:focus,
.Button:active,
.Button:active:focus,
.Button:hover {
  outline: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
  text-decoration: none;
}
.Button:hover {
  background-color: #015fa4;
}
.Button.is-disabled {
  color: #ffffff;
  background-color: #cdcdcd;
  border: 1px solid #cdcdcd;
}
.Button.is-disabled.is-recommendation {
  height: 40px;
}
.Button.Button--light {
  background-color: white;
  color: #0271c2;
  border: 1px solid #005ea8;
}
.Button.Button--light:active,
.Button.Button--light:active:focus,
.Button.Button--light:hover,
.Button.Button--light:focus {
  background-color: #dcdcdc;
  text-decoration: none;
}
.Button.Button--inverted {
  background-color: #ffffff;
  color: #005ea8;
  border: 1px solid #005ea8;
  font-size: 13px;
  padding: 0 6px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.Button.Button--inverted:active,
.Button.Button--inverted:active:focus,
.Button.Button--inverted:hover,
.Button.Button--inverted:focus {
  outline: none;
  text-decoration: none;
  color: #005ea8;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #005ea8;
}
.Button .Button-navigationSpinner {
  display: inline-block;
  position: relative;
  top: 0;
  height: 10px;
  margin-left: 22px;
  margin-right: -22px;
}
.Button .Button-navigationSpinner:after {
  height: 24px;
  width: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-width: 4px;
  content: "";
  border-color: #fff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
  top: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 50%;
  border-style: solid;
  border-radius: 50%;
  animation: spinAround 0.7s infinite linear;
}
.Button.Button--light .Button-navigationSpinner:after {
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
}
.Checkbox {
  font-size: 14px;
  display: flex;
  width: 100%;
  border: 0;
  outline: #ffffff;
  padding-left: 0;
  text-align: left;
}
.Checkbox.is-multiline {
  width: 100%;
}
.Checkbox.is-multiline .Checkbox-icon {
  margin-top: 3px;
}
.Checkbox.has-help-text {
  width: 80%;
}
.Checkbox.is-invalid .Checkbox-icon--off {
  fill: #e30613;
}
.Checkbox-button {
  display: flex;
  width: 100%;
  border: 0;
  outline: #ffffff;
  padding-left: 0;
  text-align: left;
}
.Checkbox-icon {
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 4px;
  background-color: #ffffff;
  border-radius: 3px;
}
.Checkbox-icon.Checkbox-icon--off {
  fill: #b4b4b4;
}
.Checkbox-icon.Checkbox-icon--on {
  fill: #005ea8;
}
.Checkbox-icon.Checkbox-icon--on.is-disabled {
  fill: #b4b4b4;
}
.Checkbox-icon.is-disabled {
  background-color: #dcdcdc;
}
.Checkbox-label {
  font-size: 16px;
}
.ChipsSelect-chip {
  color: #333333;
  background-color: #ffffff;
  height: 30px;
  border-radius: 23px;
  border: 1px solid #dcdcdc;
  margin: 3px;
  padding: 8px 15px;
  font-size: 13px;
  line-height: 13px;
  min-width: 64px;
}
.ChipsSelect-chip.is-selected {
  border-color: #005ea8;
  background: #ecf7fd;
}
.ChipsSelect-chip:hover {
  border-color: #005ea8;
}
.ChipsSelect-label {
  font-size: 18px;
  color: #333333;
  line-height: 20px;
  margin-bottom: 16px;
  margin-right: 40px;
}
.ChipsSelect-chipsWrapper {
  margin-right: 40px;
  margin-left: -3px;
}
.EfeedbackCommentsHeader {
  color: #005ea8;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
.EfeedbackCommentsNextButton-button {
  background-color: #f4f4f4;
  border: 1px solid #005ea8;
  color: #005ea8;
  height: 40px;
  margin-top: 10px;
}
.EfeedbackCommentsNextButton-button:disabled {
  color: #dcdcdc;
  cursor: not-allowed;
  text-decoration: none;
}
.EfeedbackCommentsNextButton-label {
  border-top: 1px solid #dcdcdc;
  color: #333333;
  font-size: 14px;
  margin-top: 5px;
  padding-top: 5px;
  text-align: center;
}
.EfeedbackRatingStars {
  width: 75px;
  position: relative;
}
.EfeedbackRatingStars-starsContainer {
  display: inline-block;
  font-size: 12px;
}
.EfeedbackRatingStars-starsContainer.is-overlay {
  position: absolute;
  white-space: nowrap;
  overflow-x: hidden;
  left: 0;
  top: 0;
}
.EfeedbackRatingStars-star {
  height: 15px;
  width: 15px;
  fill: #dcdcdc;
}
.EfeedbackRatingStars-star.is-overlay {
  fill: #f3bd00;
}
.EfeedbackRatings-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 320px) {
  .EfeedbackRatings-container.is-comparison {
    display: block;
  }
}
.EfeedbackRatings-numberOfRatings {
  display: flex;
  justify-content: center;
  color: #333333;
  margin-left: 5px;
  font-size: 14px;
}
@media not all and (min-resolution:.001dpcm) {
  .EfeedbackRatings-numberOfRatings {
    margin-top: -5px;
  }
}
@media screen and (max-width: 320px) {
  .EfeedbackRatings-container.is-comparison > .EfeedbackRatings-numberOfRatings {
    margin-left: 0;
  }
}
.EkomiLogo {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
.EkomiLogo-svg {
  pointer-events: auto;
  max-width: 100%;
  height: 58px;
}
.EkomiLogo-popupEkomiBadge {
  position: fixed;
  right: -17px;
  bottom: 5px;
  display: inline-block;
  width: 240px;
  height: 80px;
}
.EkomiLogo-popupHeadline {
  font-weight: bold;
}
.EkomiLogo-popupSubHeadline {
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
.EkomiLogo-contentPart {
  margin-bottom: 10px;
}
.EkomiLogo-popupAddressHeadline {
  padding: 8px 0;
  border-top: 1px solid #999999;
  font-weight: bold;
}
.EkomiLogo-popupContent {
  color: #333333;
}
.ExpandArrow-container {
  display: flex;
  color: #005ea8;
}
.ExpandArrow-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  font-family: Arial;
  font-size: 12px;
  fill: #005ea8;
  font-weight: 400;
  text-decoration: none solid #005ea8;
}
.ExpandArrow-icon.is-open {
  transform: rotate(180deg);
}
.Grade-mobile {
  position: relative;
  float: right;
}
.Grade-icon-mobile {
  height: 59px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: 50px 59px;
  background-image: url(/public/grade_grey.svg);
}
.Grade-icon-mobile.is-golden {
  background-image: url(/public/grade_gold.svg);
}
.Grade-calculatedValue-mobile {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}
.Grade-subText-mobile {
  position: absolute;
  top: 12px;
  width: 100%;
  text-align: center;
  font-size: 8px;
  color: #333333;
}
.Grade-gradeText-mobile {
  position: absolute;
  font-size: 10px;
  top: 45px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
  font-weight: bold;
}
.Grade-gradeText-mobile.is-longText {
  top: 47px;
  font-size: 7px;
}
.HelpText-questionMark {
  position: absolute;
  right: 17px;
  top: 17px;
  width: 20px;
  height: 20px;
  fill: #999999;
}
.HelpText-questionMark:hover {
  fill: #999999;
}
.HelpText-questionMark.is-active {
  fill: #005ea8;
}
.LoadingTariffOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 400px);
}
.PointsLogo-svg {
  height: 30px;
}
.RadioButtonSelect {
  background: #ffffff;
  border-bottom: 1px solid #b4b4b4;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.RadioButtonSelect span {
  display: flex;
  padding-block: 1.25em;
  margin-inline: 0.5em;
}
.RadioButtonSelect span:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}
.RadioButtonSelect span input {
  flex-shrink: 0;
}
.RadioButtonSelect span label {
  margin-inline-start: 0.5em;
  padding-block-start: 2px;
  font-size: 18px;
}
.RecommendationHint .RecommendationHintModal-dialog {
  max-height: none;
  background-color: #ffffff;
}
@media screen and (max-height: 768px) {
  .RecommendationHint .RecommendationHintModal-dialog {
    top: 60% !important;
  }
}
.RecommendationHintModal-row {
  max-width: 694px;
  margin-top: 10px;
}
.RecommendationHintModal-headline {
  color: #333333;
  font-size: 18px;
  margin-bottom: 16px;
  padding-right: 35px;
}
.RecommendationHintModal-ctaContainer {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.RecommendationHintModal-formInput > div > input {
  color: initial;
}
.RecommendationHint-text {
  color: #005ea8;
  text-align: right;
  font-size: 18px;
  margin-top: 10px;
}
.ReinforcementElement {
  margin-top: 5px;
  display: flex;
}
.ReinforcementElement-thumb {
  height: 28px;
  fill: #7ab51d;
}
.ReinforcementElement-thumb--birthday {
  width: 54px;
}
.ReinforcementElement-thumb--benefit-age-limit {
  width: 35px;
}
.ReinforcementElement-message {
  margin-left: 10px;
  color: #7ab51d;
  font-size: 14px;
}
.ResultSearchCriteriaHeader {
  color: #333333;
  font-size: 14px;
  padding: 5px;
  background: #ffbb1c;
}
.ResultSearchCriteriaHeader-CriteriaText {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
}
.ResultSearchCriteriaHeader-Content {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80vw;
  display: block;
  overflow: hidden;
  margin: auto 0;
}
.ResultSearchCriteriaHeader-onboardingLinkIcon {
  fill: #005ea8;
  width: 35px;
  height: 35px;
  align-self: center;
}
.ResultZeroHeader-moreInformation {
  margin: 23px 0;
  display: block;
  float: right;
}
.SocialMediaButtons {
  display: block;
  width: 100%;
  border-radius: 3px;
  font-size: 16px;
  border: 0 none;
  pointer-events: auto;
  text-align: center;
  background-color: #ffffff;
  color: #005ea8;
  border: 1px solid #005ea8;
  font-weight: bold;
  height: 50px;
}
.SocialMediaButtons-icon {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  margin-right: 15px;
}
.Spinner:after {
  content: "";
  display: inline-block;
  height: 30px;
  width: 30px;
  border-style: solid;
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-width: 10px;
  animation: spinAround 800ms infinite linear;
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.SpinnerWithOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 400px);
}
.StickyButton-container {
  box-shadow: 0 1px 4px rgba(51, 51, 51, 0.3);
  width: 100%;
  background: #f4f4f4;
  position: fixed;
  left: 0;
  bottom: 62px;
  transition: all 0.5s ease 0s;
  z-index: 2;
}
.StickyButton-container.is-app {
  bottom: env(safe-area-inset-bottom);
}
.StickyButton-button {
  margin: 10px;
  width: 95%;
}
.Tipp {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Tipp-box {
  background-color: #cae7a8;
  width: 12%;
  text-align: center;
  color: #005EA8;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  margin-right: 15px;
  flex: 12%;
}
.Tipp-box:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  position: absolute;
  pointer-events: none;
  border-left-color: #cae7a8;
  border-width: 10px;
  margin-top: -10px;
}
.Tipp-text {
  margin-left: 20px;
  font-size: 14px;
  flex: 85%;
  width: 85%;
}
.TrustLogos-logoContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 24px;
}
.TuevLogo {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
.TuevLogo-svg {
  max-width: 100%;
  height: 55px;
  pointer-events: auto;
}
.TuevLogo-popupTuevBadge {
  width: 165px;
  height: 90px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: inline-block;
}
.TuevLogo-popupContent {
  color: #333333;
}
.TuevLogo-popupHeadline {
  font-weight: bold;
}
.TuevLogo-popupSubHeadline {
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
.TuevLogo-popupAddressHeadline {
  margin-top: 10px;
  padding: 8px 0;
  border-top: 1px solid #999999;
  font-weight: bold;
}
.ValidationBar-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  z-index: 1;
  transition-property: width;
  transition-duration: 0.25s;
}
.ValidationBar-bar.is-error {
  background-color: #c82d2d;
  width: 100%;
}
.ValidationBar-bar.is-valid {
  background-color: #7ab51d;
  width: 100%;
}
.ValidationBar {
  position: relative;
}
.PersonalDataSection-container {
  display: block;
  padding: 0 15px;
}
.PersonalDataSection-row {
  position: relative;
  width: 100%;
  margin-right: 50px;
  margin-bottom: 14px;
}
.PersonalDataSection-label {
  height: 20px;
  font-size: 14px;
  width: 40%;
  color: #666666;
}
.PersonalDataSection-labelSubtitle {
  font-size: 14px;
  color: #666666;
  line-height: 16px;
  margin-top: 5px;
}
.PersonalDataSection-bestSso {
  margin-bottom: 30px;
  padding-left: 35px;
}
.PersonalDataSection-bestSsoFullName {
  font-weight: bold;
}
.PersonalDataSection-bestSsoPersonalData {
  flex: 1;
  font-size: 16px;
}
.PersonalDataSection-bestSsoChange {
  flex: 0 92px;
  font-size: 15px;
}
.PersonalDataSection-bestSsoSideHelpText {
  margin-top: -8px;
}
.PersonalDataSection-selectBoxContainer {
  margin-right: 0px;
}
.PersonalDataSection-agreementLink {
  text-decoration: underline;
}
.PersonalDataSection-agreementLink.is-disabled {
  pointer-events: none;
}
.PersonalDataSection-questionMark {
  top: 0;
}
.PersonalDataSection-checkbox .Checkbox-label {
  font-size: 14px;
  margin-bottom: 0px;
}
.PersonalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}
.PersonalDataSection-titleContainer {
  display: flex;
}
.PersonalDataSection-separator {
  margin-bottom: 24px;
}
.PersonalDataSection-ssoData {
  font-size: 20px;
  color: #333333;
  margin-bottom: 12px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PersonalDataSection-ssoData a {
  line-height: 0;
}
.PersonalDataSection-ssoData a svg {
  margin-right: 15px;
  position: initial;
}
.PersonalDataSection-agreement > div {
  align-items: flex-start;
  width: unset;
  height: unset;
}
.PersonalDataSection-versicherungPlusAdvertising {
  margin: 24px 0;
  padding-bottom: 32px;
  border-block: 1px solid #dcdcdc;
}
.NoTariffsFound {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NoTariffsFound-header {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.NoTariffsFound-text {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}
.NoTariffsFound-cta {
  margin-top: 20px;
  margin-bottom: 10px;
}
.NoTariffsFound-hint {
  display: flex;
  align-items: center;
  color: #7ab51d;
  margin-top: 10px;
}
.NoTariffsFound-checkmark {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.NoTariffsFound-iconContainer {
  display: flex;
  align-items: center;
}
.NoTariffsFound-icon {
  width: 180px;
}
.ResultFilterChips-selectedFiltersListContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 8px;
  margin-bottom: 10px;
}
.ResultFilterChips-filterContainer {
  background-color: #ecf7fd;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 10px;
  height: 40px;
  box-sizing: content-box;
  font-size: 14px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.ResultFilterChips-unselectFilterButton {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  fill: #005ea8;
}
.ResultFilterChips-unselectAllFiltersButton {
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: #005ea8;
}
.ResultFilterChips-unselectAllFiltersButton:hover {
  text-decoration: underline;
}
.FirstViewFilterChips {
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 5px;
  min-height: 28px;
  box-sizing: content-box;
  font-size: 14px;
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
  word-break: break-word;
}
@media screen and (max-width: 320px) {
  .FirstViewFilterChips {
    font-size: 13px;
  }
}
.FirstViewFilterChips.is-selected {
  background-color: #ecf7fd;
  color: #333333;
}
.FirstViewFilterChips.is-disabled {
  color: #b4b4b4;
  border-color: #dcdcdc;
  background-color: #fafafa;
  box-shadow: none;
}
.FirstViewFilterChips-unselectFilterButton {
  height: 12px;
  width: 12px;
  margin-left: 10px;
  fill: #005ea8;
  flex-shrink: 0;
}
.FirstViewFilterChips-fromPriceText {
  font-size: 12px;
  min-width: 40px;
  color: #999999;
  margin-left: 10px;
  margin-right: 5px;
  white-space: nowrap;
}
.FirstViewFilterChips-fromPriceText.is-unselect-icon-shown {
  margin-right: 0;
}
@media screen and (max-width: 320px) {
  .FirstViewFilterChips-fromPriceText.is-long-text {
    margin-left: 0;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .FirstViewFilterChips-fromPriceText.is-long-text {
    margin-left: 0;
  }
}
@media screen and (max-width: 320px) {
  .FirstViewFilterChips-contentContainer.is-long-text {
    display: flex;
    flex-direction: column;
    max-width: 210px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .FirstViewFilterChips-contentContainer.is-long-text {
    display: flex;
    flex-direction: column;
  }
}
.ResultInsurancesFilter-chipsList {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: scroll;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: -5px;
}
.ResultInsurancesFilter-insuranceChip {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  margin-right: 10px;
  position: relative;
}
.ResultInsurancesFilter-insuranceChip.is-selected {
  background-color: #ecf7fd;
}
.ResultInsurancesFilter-logo {
  height: 20px;
  max-width: 100px;
  margin-right: 10px;
}
.ResultInsurancesFilter-logo.is-not-available {
  filter: grayscale(1);
}
.ResultInsurancesFilter-fromPrice {
  font-size: 12px;
  color: #999999;
}
.ResultInsurancesFilter-closeIcon {
  width: 10px;
  height: 10px;
  fill: #005ea8;
  margin-left: 10px;
}
.ResultInsurancesFilter-closeIcon.is-filter-applied {
  margin-left: 0;
}
.ResultInsurancesFilter-closeIcon.is-not-available {
  fill: #333333;
}
.ResultInsurancesFilter-notAvailableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #999999;
  opacity: 0.3;
}
.ResultTab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  background-color: #ffffff;
  font-size: 14px;
  border-bottom: 1px solid #dcdcdc;
  height: 45px;
  padding: 5px 0;
  text-align: center;
}
.ResultTab.is-middle {
  justify-content: space-between;
}
.ResultTab.is-middle::after {
  content: "";
  height: 24px;
  width: 1px;
  border-left: 1px solid #dcdcdc;
  margin-right: -1px;
}
.ResultTab.is-middle::before {
  content: "";
  height: 24px;
  width: 1px;
  border-right: 1px solid #dcdcdc;
  margin-left: -1px;
}
.ResultTab-icon {
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  fill: #333333;
}
.ResultTab-icon.is-dot-visible::after {
  content: '';
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  right: 0;
  border-radius: 50%;
  background: #e30613;
}
.ResultTab-comparisonIcon {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  fill: #333333;
}
.ResultTab-sortIconContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-items: center;
}
.ResultTab-sortingTextWrapper {
  text-align: start;
  display: grid;
}
.ResultTab-sortingText {
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ResultTab-sortingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
}
.ResultTariffListGfBanner-modalContainer {
  padding: 5px;
}
.ResultTariffListGfBanner-container {
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  display: block;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.ResultTariffListGfBanner-bannerBody {
  background-color: #f07c00;
  height: 25px;
  display: inline-block;
  vertical-align: top;
  width: 240px;
}
.ResultTariffListGfBanner-bannerBody:after {
  content: "";
  position: relative;
  left: calc(100% - 2px);
  top: -1px;
  border-left: 2px solid transparent;
  border-right: 13px solid transparent;
  border-top: 25px solid #f07c00;
}
.ResultTariffListGfBanner-bannerBody.is-modal {
  border-top-left-radius: 10px;
  width: 260px;
}
.ResultTariffListGfBanner-bannerBody.is-modal:after {
  top: -2px;
}
.ResultTariffListGfBanner-bannerBody.is-long-occupation {
  height: 50px;
}
.ResultTariffListGfBanner-bannerBody.is-long-occupation:after {
  border-top: 50px solid #f07c00;
}
.ResultTariffListGfBanner-bannerCorner {
  display: inline-block;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 25px solid #f07c00;
  margin-left: -0.2px;
}
.ResultTariffListGfBanner-bannerCorner.is-long-occupation {
  border-top: 50px solid #f07c00;
}
.ResultTariffListGfBanner-bannerContainer {
  padding: 10px 0 10px 10px;
}
.ResultTariffListGfBanner-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}
.ResultTariffListGfBanner-text {
  font-size: 15px;
}
.ResultTariffListGfBanner-priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.ResultTariffListGfBanner-price {
  font-size: 24px;
  color: #7ab51d;
  white-space: nowrap;
  margin-bottom: -8px;
}
.ResultTariffListGfBanner-priceText {
  font-size: 11px;
}
.ResultTariffListGfBanner-modalCloseContainer {
  display: flex;
  justify-content: flex-end;
}
.ResultTariffListGfBanner-modalClose {
  width: 16px;
  height: 16px;
  fill: #b4b4b4;
  position: absolute;
  top: 0;
}
.ResultTariffListGfBanner-modalHeader {
  font-size: 19px;
  color: #333333;
  margin-top: 10px;
}
.ResultTariffListGfBanner-modalText {
  font-size: 18px;
  color: #333333;
}
.ResultTariffListGfBanner-modalPriceContainer {
  margin-top: 20px;
}
.ResultTariffListGfBanner-modalPrice {
  font-size: 24px;
  color: #7ab51d;
  display: flex;
  justify-content: flex-end;
  line-height: 25px;
}
.ResultTariffListGfBanner-gfUspList {
  margin-top: 20px;
}
.ResultTariffListGfBanner-modalPriceInterval {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}
.ResultTariffListGfBanner-gfUspContainer {
  display: flex;
  margin-top: 8px;
}
.ResultTariffListGfBanner-gfUspBullet {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.ResultTariffListGfBanner-gfUspText {
  font-size: 16px;
  color: #333333;
}
.ResultTariffListGfBanner-modalActionButtons {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 20px;
}
.ResultTariffListGfBanner-modalButtonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ResultTariffListGfBanner-modalButton.back {
  margin-top: 10px;
  background-color: #f4f4f4;
  border: 1px solid #dcdcdc;
  color: #666666;
}
.ResultTariffListGfBanner-modalButton.back:active,
.ResultTariffListGfBanner-modalButton.back:active:focus,
.ResultTariffListGfBanner-modalButton.back:hover,
.ResultTariffListGfBanner-modalButton.back:focus {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.24);
}
.ResultTariffListGfBanner-modalButtonText {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
.TariffListOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.TariffListOverlay.is-withTariffs .TariffListOverlay-blocker {
  opacity: 0.7;
}
.TariffListOverlay-spinner {
  text-align: center;
}
.TariffListOverlay-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.TariffListOverlay-spinnerText {
  color: #005ea8;
  font-size: 18px;
  margin-top: 5px;
}
.TariffListOverlay-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.KnownCustomerConsultantBox {
  margin-bottom: 10px;
}
.KnownCustomerConsultantBox-headlineText {
  font-size: 20px;
  color: #333333;
}
.KnownCustomerConsultantBox-box {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
}
.KnownCustomerConsultantBox-box .KnownCustomerConsultantBox-photo {
  border-radius: 47px;
  width: 95px;
  height: 95px;
}
.KnownCustomerConsultantBox-box img {
  border: solid 1px #dcdcdc;
}
.KnownCustomerConsultantBox-info {
  display: flex;
  gap: 9px;
  flex-direction: column;
}
.KnownCustomerConsultantBox-name {
  font-size: 16px;
  color: #333333;
}
.KnownCustomerConsultantBox-experience {
  font-size: 12px;
  color: #666666;
}
.KnownCustomerConsultantBox-phone {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #005ea8;
}
.KnownCustomerConsultantBox-phone .KnownCustomerConsultantBox-phone-icon {
  width: 20px;
  height: 20px;
  fill: #005ea8;
}
.KnownCustomerConsultantBox-ctaButton {
  margin-top: 24px;
}
.ThankyouTariffInfo-tariffInfoRow {
  display: flex;
}
.ThankyouTariffInfo-tariffInfoLabel {
  min-width: 125px;
  font-size: 16px;
}
.ThankyouTariffInfo-tariffInfoDescription {
  font-weight: 700;
  font-size: 16px;
}
.ThankyouAppointmentConfirmation {
  margin-bottom: 10px;
}
.ThankyouAppointmentConfirmation-headlineContainer {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-family: Arial;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 20px;
}
.ThankyouAppointmentConfirmation-greenCheckmark {
  width: 25px;
  min-width: 25px;
  height: 20px;
  margin-top: 2px;
  margin-right: 10px;
  fill: #7ab51d;
}
.ThankyouAppointmentConfirmation-greenCheckmark.is-mailConfirmation {
  margin-top: 0;
}
.ThankyouAppointmentConfirmation-infoContainer {
  margin-top: 15px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
}
.ThankyouAppointmentConfirmation-textarea {
  color: #333333;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Verdana;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  background-size: cover;
  height: 100px;
  width: 100%;
  resize: none;
  background-clip: padding-box;
}
.ThankyouAppointmentConfirmation-textarea.is-error {
  border: 1px solid #c82d2d;
  margin-bottom: 0;
}
.ThankyouAppointmentConfirmation-bottomButton {
  margin-bottom: 15px;
}
.ThankyouAppointmentConfirmation-mailContainer {
  margin-top: 15px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.ThankyouAppointmentConfirmation-timeSpan {
  white-space: nowrap;
}
.ThankyouAppointmentConfirmation-emailErrorText {
  font-size: 14px;
  color: #c82d2d;
  margin-bottom: 15px;
}
.ThankyouAppointmentConfirmation-thirdViewButton {
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.ThankyouAppointmentConfirmation-thirdViewButton:active,
.ThankyouAppointmentConfirmation-thirdViewButton:active:focus,
.ThankyouAppointmentConfirmation-thirdViewButton:hover,
.ThankyouAppointmentConfirmation-thirdViewButton:focus {
  background-color: #fafafa;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.ThankyouAppointment {
  margin-bottom: 10px;
}
.ThankyouAppointment-description {
  font-size: 18px;
  margin-bottom: 10px;
}
.ThankyouAppointment-timeslotNotAvailableHeaderContainer {
  display: flex;
  align-items: center;
}
.ThankyouAppointment-modalText {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  margin-bottom: 15px;
}
.ThankyouAppointment-modalHeader {
  font-size: 16px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 5px;
}
.ThankyouAppointment-timeslotNotAvailableHeaderText {
  font-size: 16px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 5px;
}
.ThankyouAppointment-expiredModalButtons {
  display: flex;
  justify-content: space-between;
}
.ThankyouAppointment-callButton {
  display: flex;
  align-items: center;
}
.ThankyouAppointment-modalBackButton {
  width: 50%;
}
.ThankyouAppointment-phoneSvg {
  font-size: 16px;
  fill: #005ea8;
  width: 15px;
  height: 22px;
  margin-right: 5px;
}
@media screen and (max-width: 320px) {
  .ThankyouAppointment-phoneSvg {
    display: none;
  }
}
.RadioButtonGroup {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 16px;
}
.RadioButtonGroup .RadioButton-container {
  border-bottom: solid 1px #ebebeb;
  margin-bottom: 16px;
}
.RadioButtonGroup .RadioButton-container:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.RadioButton-input {
  margin: 0px;
  flex-shrink: 0;
}
.RadioButton-container {
  display: flex;
  gap: 10px;
}
.ConsultantBox-container {
  height: 100%;
  position: relative;
  border: 1px solid #dcdcdc;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  stroke-width: 1;
  margin: 25px 0;
  padding: 10px 0;
}
.ConsultantBox-headerText {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.ConsultantBox-content {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
}
.ConsultantBox-photoContainer {
  padding-right: 5px;
}
.ConsultantBox-photo {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #dcdcdc;
  margin: auto;
}
.ConsultantBox-consultantDetails {
  justify-content: center;
  margin: 2px auto;
}
.ConsultantBox-name {
  text-align: center;
  font-size: 16px;
  color: #333333;
}
.ConsultantBox-companyName {
  text-align: center;
  font-size: 12px;
  color: #333333;
  margin-top: 5px;
}
.ConsultantBox-phoneNumber {
  font-size: 14px;
  color: #005ea8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.ConsultantBox-phoneSvg {
  fill: #005ea8;
  width: 27px;
  height: 27px;
}
.ConsultantBox-appointmentButton {
  margin-top: 10px;
}
.ThankyouAppointmentBenefits-headline {
  font-size: 18px;
}
.ThankyouAppointmentBenefits-container,
.ThankyouAppointmentBenefits-bulletsContainer {
  display: flex;
  flex-direction: column;
}
.ThankyouAppointmentBenefits-container {
  margin-bottom: 10px;
}
.ThankyouAppointmentBenefits-bulletsContainer {
  padding-top: 10px;
}
.ThankyouAppointmentBenefits-bulletsContainer > div {
  margin-bottom: 10px;
}
.ThankyouAppointmentBenefits-bulletsContainer :last-child {
  margin-bottom: 0px;
}
.ThankyouAppointmentHeader-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
}
.ThankyouAppointmentHeader-icon {
  width: 25px;
  height: 20px;
  min-width: 25px;
  margin-top: 3px;
  fill: #7ab51d;
}
.ThankyouAppointmentHeader-textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.ThankyouAppointmentHeader-text {
  font-size: 20px;
}
.ThankyouAppointmentHeader-subText {
  font-size: 14px;
}
.AppointmentStickyButton-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 68px;
  transition: all 0.5s ease 0s;
  width: 100%;
  background: #ffffff;
  padding: 16px;
  box-shadow: 0 0 1px transparent;
}
.AppointmentStickyButton-container.is-app,
.AppointmentStickyButton-container.is-separate-appointment {
  bottom: env(safe-area-inset-bottom);
}
.ThankyouAppointmentsCalendar-error {
  font-size: 14px;
  color: #c82d2d;
  display: block;
}
.ThankyouAppointmentsCalendar-cancellationButton {
  margin: 10px auto auto;
}
.ThankyouAppointmentBenefit-bulletContainer {
  display: flex;
  justify-content: flex-start;
}
.ThankyouAppointmentBenefit-bulletCheckIcon {
  width: 16px;
  height: 12px;
  min-width: 16px;
  fill: #005ea8;
  margin-right: 10px;
  margin-top: 4px;
}
.ThankyouAppointmentBenefit-bulletText {
  font-size: 16px;
  color: #333333;
}
.ThankyouAppointmentBookingResult {
  border: 2px solid #999999;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 15px 10px;
  margin-top: 10px;
  white-space: nowrap;
}
@media screen and (max-width: 375px) {
  .ThankyouAppointmentBookingResult {
    font-size: 13px;
  }
}
.ThankyouAppointmentBookingResult-date {
  margin-left: 5px;
}
.ThankyouAppointmentsCalendarInfo-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.ThankyouAppointmentsCalendarInfo-icon {
  width: 14px;
  height: 14px;
  min-width: 14px;
  fill: #666666;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 1px;
}
.ThankyouAppointmentsCalendarInfo-text {
  font-size: 13px;
}
.BackdatedPriceHint {
  background: #008300;
  color: #ffffff;
  width: 100%;
  line-height: 34px;
  font-size: 14px;
  margin: 0px auto 10px;
  display: flex;
  position: sticky;
  top: 45px;
  z-index: 2;
}
.BackdatedPriceHintLightBulb {
  fill: #ffffff;
  color: #ffffff;
  height: 22px;
  width: 22px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.BackdatedPriceHintText {
  text-align: center;
}
.BackdatedPriceHintIcon {
  text-align: right;
  height: 15px;
  width: 15px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 5px;
  fill: #ffffff;
}
.BackdatedPriceHint-modalHeadline {
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  width: 95%;
  margin-bottom: 20px;
  line-height: 1.4;
  text-align: left;
}
.BackdatedPriceHint-modalContent {
  color: #333333;
  line-height: 1.4;
}
.OnboardingPage-container {
  background-color: #ffffff;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}
.OnboardingPage-ctaButton {
  margin-top: 24px;
}
.OnboardingPage-toResultsButton {
  margin-top: 16px;
}
.OnboardingPage-BackButton {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: -5px;
}
.OnboardingPage-trustLogos {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;
}
.OnboardingPage-skipToResultButton {
  margin-top: 16px;
}
.TransparencyBox {
  margin: 10px;
  padding: 10px 15px 10px 15px;
  background-color: #dcdcdc;
  color: #333333;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.TransparencyBox-close {
  width: 16px;
  height: 16px;
  margin-top: 3px;
  cursor: pointer;
  fill: #b4b4b4;
}
.AppointmentBlacklistedCustomers-container {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  height: calc(100vh - 220px);
}
.AppointmentBlacklistedCustomers-title {
  display: flex;
  align-items: center;
  margin-bottom: 27px;
}
.AppointmentBlacklistedCustomers-title svg {
  width: 27px;
  height: 24px;
}
.AppointmentBlacklistedCustomers-title div {
  color: #333333;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  margin: 0px;
}
.AppointmentBlacklistedCustomers-informative-text {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.AppointmentBlacklistedCustomers-phone {
  display: flex;
  margin-left: 40px;
}
.AppointmentBlacklistedCustomers-phone svg {
  width: 16px;
  height: 22px;
}
.AppointmentBlacklistedCustomers-phone div :first-child {
  color: #005ea8;
  font-size: 20px;
  height: 24px;
  margin: 0;
}
.AppointmentBlacklistedCustomers-phone div :last-child {
  color: #333333;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 12px;
}
.AppointmentBlacklistedCustomers-check-info {
  color: #333333;
  font-size: 16px;
  margin-top: 30px;
}
.AppointmentCalendarPage-consultantBoxWrapper {
  margin-bottom: 15px;
}
.AppointmentConsultantBox-container {
  height: 100%;
  background-color: #ffffff;
}
.AppointmentConsultantBox-headline {
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.AppointmentConsultantBox-subHeadline {
  font-size: 16px;
  margin-top: 15px;
}
.AppointmentConsultantBox-personalHeadline {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.AppointmentConsultantBox-experience {
  text-align: center;
  font-size: 12px;
}
.AppointmentConsultantBox-name {
  text-align: center;
  font-size: 16px;
  color: #333333;
  margin-top: 6px;
}
.AppointmentConsultantBox-photoAndQuote {
  display: flex;
  justify-content: space-between;
}
.AppointmentConsultantBox-photoContainer {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AppointmentConsultantBox-photo.is-substituteHint {
  height: 57px;
  width: 57px;
  margin-left: 5px;
}
.AppointmentConsultantBox-quote {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #dcdcdc;
  background-color: #ecf7fd;
  background-size: cover;
  padding: 15px;
  font-size: 14px;
}
.AppointmentConsultantBox-quote .Spinner {
  margin: auto;
}
.AppointmentConsultantBox-arrow {
  width: 25px;
  height: 25px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #ecf7fd;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: 35px;
  left: -14px;
}
.AppointmentConsultantBox-text {
  word-wrap: break-word;
  width: auto;
  hyphens: auto;
  word-break: break-word;
}
.AppointmentConsultantBox-time {
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 14px;
}
.AppointmentDeletedCustomers-container {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  min-height: calc(100vh - 215px);
}
.AppointmentDeletedCustomers-title {
  display: flex;
  align-items: center;
}
.AppointmentDeletedCustomers-title svg {
  width: 27px;
  height: 27px;
}
.AppointmentDeletedCustomers-title div {
  color: #333333;
  height: 20px;
  font-size: 20px;
  margin-left: 10px;
  line-height: 19px;
  margin-bottom: 0px;
  margin-top: 1px;
}
.AppointmentDeletedCustomers-informative-text {
  color: #333333;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 3px;
  margin-right: 3px;
}
.AppointmentDeletedCustomers-button {
  background-color: #0271c2;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
}
.AppointmentDeletedCustomers-button svg {
  fill: #ffffff;
  margin-bottom: -2px;
}
.AppointmentDeletedCustomers-button .AppointmentDeletedCustomers-svg-email {
  width: 24px;
  height: 25px;
  fill: #ffffff;
  margin-bottom: -7px;
}
.AppointmentDeletedCustomers-text-hour {
  color: #333333;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
.CampaignBanner-container {
  background-color: #ff6c68;
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 40px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.CampaignBanner-container.is-deadline {
  background-color: #febb00;
}
.CampaignBanner-info {
  fill: #ffffff;
  height: 15px;
  width: 15px;
  flex-shrink: 0;
}
.CampaignBanner-headline {
  font-weight: 700;
}
.CampaignBanner-modalContent {
  font-size: 14px;
  color: #333333;
}
.Cashback-spinner {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.CashbackForm {
  margin: 20px;
  color: #333333;
  font-weight: 400;
}
.CashbackForm-greetings-container {
  margin-bottom: 16px;
}
.CashbackForm-container {
  margin-top: 16px;
}
.CashbackForm-top-header {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
}
.CashbackForm-sub-header {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 16px;
}
.CashbackForm-greetings {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 0;
}
.CashbackForm-info {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 0;
}
.CashbackForm-separator {
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  margin: 0;
}
.CashbackForm-form-item {
  font-size: 16px;
  margin-bottom: 16px;
}
.CashbackForm-input {
  width: 100%;
  margin-bottom: 10px;
}
.CashbackForm-bankDetails {
  width: 100%;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin: 4px 0 0;
  color: #333333;
  font-size: 14px;
}
.CashbackForm-ibanInputBankDetails {
  margin-bottom: 0px;
}
.CashbackForm-footer {
  font-size: 13px;
  color: #333333;
  line-height: 20px;
}
.CashbackForm-footer .CashbackForm-footer-link {
  margin-left: 4px;
  text-decoration: underline;
}
.CashbackForm-cta {
  width: 100%;
  margin-top: 20px;
}
.CashbackForm-cta button {
  width: 100%;
}
.CashbackErrorMessage {
  margin: 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;
}
.CashbackErrorMessage-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 16px;
  color: #333333;
  font-weight: 400;
  line-height: 30px;
}
.CashbackErrorMessage-row .CashbackErrorMessage-header {
  font-size: 20px;
}
.CashbackErrorMessage-row .CashbackErrorMessage-body {
  font-size: 16px;
}
.CashbackErrorMessage-row .CashbackErrorMessage-circle-svg {
  height: 29px;
  fill: #b4b4b4;
  margin-top: 6px;
}
.CashbackErrorMessage-row .CashbackErrorMessage-phone-svg {
  height: 32px;
  fill: #005ea8;
  margin-top: 6px;
}
.ComparisonDetails-feature {
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  min-height: 40px;
  position: relative;
}
.ComparisonDetails-featureTitle {
  min-height: 40px;
  position: relative;
  background-color: #EDEDED;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}
.ComparisonDetails-featureTitleText {
  padding: 5px 25px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}
.ComparisonDetails-tariffFeature {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-left: 1px solid #dcdcdc;
  flex: 1;
  text-align: center;
  padding: 5px;
}
.ComparisonDetails-tariffFeature > div > svg {
  margin-top: 6px;
}
.ComparisonDetails-tariffFeature:first-child {
  border-left: none;
}
.ComparisonDetails-helpText {
  right: 13px;
  top: 10px;
}
.ComparisonInquiryButton {
  padding: 0 10px;
  margin-bottom: 15px;
}
.ComparisonSelectedTariffsFeatures {
  background-color: #ffffff;
  padding-top: 10px;
}
.ComparisonSelectedTariffsFeatures-filterOptions {
  display: flex;
  margin: 0 10px;
  border-radius: 30px;
  background-color: #EDEDED;
  -webkit-tap-highlight-color: transparent;
}
.ComparisonSelectedTariffsFeatures-option {
  color: #005ea8;
  border-radius: 20px;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
  flex: 1;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}
.ComparisonSelectedTariffsFeatures-option.is-selected {
  color: #ffffff;
  background-color: #005ea8;
}
.ComparisonSelectedTariffsFeatures-categories {
  margin: 10px 0;
}
.ComparisonSelectedTariffsFeatures-categories:last-child {
  margin-bottom: 0;
}
.ComparisonSelectedTariffsFeatures-categoryTitle {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #dcdcdc;
  color: #005ea8;
}
.ComparisonSelectedTariffsFooter {
  display: flex;
}
.ComparisonSelectedTariffsFooter-inquiryButtonContainer {
  background-color: #ffffff;
  border-left: 1px solid #dcdcdc;
  position: relative;
  flex: 1;
  padding: 10px 0;
}
.ComparisonSelectedTariffsFooter-inquiryButtonContainer:first-child {
  border-left: none;
}
.ComparisonSelectedTariffsHeader {
  display: flex;
  border-top: 1px solid #dcdcdc;
}
.ComparisonSelectedTariffsHeader-tariffBlock {
  border-left: 1px solid #dcdcdc;
  background-color: #ffffff;
  flex: 1;
  position: relative;
  min-width: 1px;
  /* This is because a bug in firefox wtih long tariffnames */
}
.ComparisonSelectedTariffsHeader-tariffBlock.is-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.ComparisonSelectedTariffsHeader-tariffBlock:first-child {
  border-left: none;
}
.ComparisonSelectedTariffsHeader-tariffBlock.is-resultZero {
  background-color: #ecf7fd;
}
.ComparisonSelectedTariffsHeader-price {
  font-size: 22px;
  line-height: 22px;
  margin-top: 6px;
  color: #333333;
}
.ComparisonStickyHeader-priceAddition {
  font-size: 13px;
}
.ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
  margin-top: 60px;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;
}
.ComparisonSelectedTariffsHeader-wishlistHeart {
  margin-bottom: 13px;
}
.ComparisonSelectedTariffsHeader-insuranceLogo {
  display: block;
  height: 50px;
  max-width: 130px;
}
.ComparisonSelectedTariffsHeader-insuranceLogo.is-compact {
  max-width: 100px;
}
.ComparisonSelectedTariffsHeader-close {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: auto;
  width: 16px;
  height: 16px;
  fill: #b4b4b4;
}
.ComparisonSelectedTariffsHeader-close.is-compact {
  width: 14px;
  height: 14px;
  top: 5px;
  right: 5px;
}
.ComparisonSelectedTariffsHeader-tariffName {
  text-align: center;
  color: #333333;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 700;
  overflow: hidden;
}
.ComparisonSelectedTariffsHeader-backDatedPriceContainer,
.ComparisonSelectedTariffsHeader-priceContainer {
  color: #333333;
  text-align: center;
}
.ComparisonSelectedTariffsHeader-backDatedPriceContainer {
  margin-top: -15px;
}
.ComparisonSelectedTariffsHeader-priceContainer {
  font-size: 13px;
}
.ComparisonSelectedtariffsHeader-resultZeroContainer {
  position: absolute;
  text-align: left;
  margin-left: 10px;
  margin-right: 25px;
  margin-top: 10px;
}
.ComparisonSelectedtariffsHeader-resultZeroText {
  font-size: 12px;
  font-weight: normal;
  color: white;
  width: 97%;
}
@media screen and (max-width: 320px) {
  .ComparisonSelectedtariffsHeader-resultZeroText.is-compact {
    font-size: 8.5px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .ComparisonSelectedtariffsHeader-resultZeroText.is-compact {
    font-size: 8.5px;
  }
}
.ComparisonSelectedTariffsHeader-resultZeroHeader {
  min-width: 0;
  height: 34px;
  text-align: center;
  background-color: #002646;
  display: inline-block;
  width: 100px;
  vertical-align: top;
  padding: 0;
}
.ComparisonSelectedTariffsHeader-resultZeroHeader.is-short-text {
  height: 17px;
}
.ComparisonSelectedTariffsHeader-resultZeroHeader.is-long-text {
  width: 110px;
}
@media screen and (max-width: 320px) {
  .ComparisonSelectedTariffsHeader-resultZeroHeader.is-compact {
    width: 75px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .ComparisonSelectedTariffsHeader-resultZeroHeader.is-compact {
    width: 75px;
  }
}
.ComparisonSelectedTariffsHeader-text-suffix {
  color: #f79a31;
  margin: 0;
}
.ComparisonSelectedTariffsHeader-resultZeroHeaderCorner {
  display: inline-block;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 34px solid #002646;
}
.ComparisonSelectedTariffsHeader-resultZeroHeaderCorner.is-short-text {
  border-top: 17px solid #002646;
}
.ComparisonSelectedTariffsHeader-oldPrice {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  display: flex;
  justify-content: center;
  margin-top: -14px;
  color: #c82d2d;
}
.ComparisonSelectedTariffsHeader-tooltip {
  width: 750px;
}
.ComparisonSelectedTariffsHeader-infoContainer {
  height: 12px;
  width: 12px;
  fill: #B4b4b4;
  margin-left: 3px;
  pointer-events: auto;
  cursor: help;
}
.ComparisonSelectedTariffsInfo {
  display: flex;
  border-bottom: 1px solid #dcdcdc;
}
.ComparisonSelectedTariffsInfo-container {
  background-color: #ffffff;
  margin: 0;
  padding-top: 10px;
  border-left: 1px solid #dcdcdc;
  flex: 1;
}
.ComparisonSelectedTariffsInfo-container:first-child {
  border-left: none;
}
.ComparisonSelectedTariffsInfo-container.is-resultZero {
  background-color: #ecf7fd;
}
.ComparisonSelectedTariffsInfo-container .ComparisonSelectedTariffsInfo-campaignBanner {
  padding: 0 10px 10px;
}
.ComparisonSelectedTariffsInfo-grade {
  display: flex;
  height: 85px;
  justify-content: center;
  padding-top: 10px;
}
.ComparisonSelectedTariffsInfo-grade > .has-bottomborder {
  border-bottom: 1px solid #dcdcdc;
}
.ComparisonSelectedTariffsInfo-efeedbackContainer {
  display: flex;
  justify-content: center;
  min-height: 40px;
}
.ComparisonSelectedTariffsInfo-noFeedback {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  color: #cdcdcd;
}
.ComparisonStickyHeader {
  display: none;
  background: white;
  position: absolute;
  width: 100%;
}
.ComparisonPage-stickyContainer .is-sticky {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 100;
  background: white;
}
.ComparisonPage-stickyContainer .is-sticky .ComparisonStickyHeader {
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.ComparisonStickyHeader-insuranceLogo {
  height: 35px;
  max-width: 100%;
  margin-bottom: 11px;
  margin-top: 10px;
}
.ComparisonStickyHeader-backdatedPriceContainer {
  margin-top: -15px;
}
.ComparisonStickyHeader-priceContainer,
.ComparisonStickyHeader-backdatedPriceContainer {
  display: block;
  text-align: center;
  min-width: 90px;
  line-height: 15px;
  padding-bottom: 5px;
}
.ComparisonStickyHeader-priceContainer {
  color: #333333;
}
.ComparisonStickyHeader-price {
  color: #333333;
  font-size: 20px;
}
.ComparisonStickyHeader-tariffBlock {
  border-left: 1px solid #dcdcdc;
  background-color: #ffffff;
  flex: 1;
  position: relative;
  min-width: 1px;
  /* This is because a bug in firefox wtih long tariffnames */
  padding: 0 10px 0;
}
.ComparisonStickyHeader-tariffBlock.is-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.ComparisonStickyHeader-tariffBlock:first-child {
  border-left: none;
}
.ComparisonStickyHeader-tariffBlock.is-resultZero {
  background-color: #ecf7fd;
}
.ComparisonStickyHeader-tariffBlock:last-of-type {
  border-right: none;
}
.ComparisonStickyHeader-tariffBlock.is-resultZero {
  background-color: #ecf7fd;
}
.ComparisonStickyHeader-imageContainer {
  display: table;
  margin: auto auto 5px;
}
.ComparisonStickyHeader-oldPrice {
  font-size: 11px;
  line-height: 11px;
  color: #c82d2d;
  text-decoration: line-through;
  margin-bottom: 5px;
}
.ConsultantCheckpoints-wrapper {
  background-color: #ffffff;
  border: 1px, solid, #dcdcdc;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  padding: 10px;
}
.ConsultantCheckpoints-wrapper.is-comparison {
  margin: 0 15px;
  margin-bottom: 24px;
}
.ConsultantCheckpoints-checkpointWrapper,
.ConsultantCheckpoints-phoneNumberWrapper {
  display: flex;
  align-items: center;
}
.ConsultantCarouselCheckpoints-checkpointText {
  flex: 9;
  font-size: 14px;
  color: #333333;
  font-family: Arial, serif;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
}
.ConsultantCarouselCheckpoints-checkpointIconContainer {
  flex: 2;
  width: 25px;
  margin-top: 5px;
  margin-left: -3%;
}
@media only screen and (max-width: 375px) {
  .ConsultantCarouselCheckpoints-checkpointIconContainer {
    margin-left: -30px;
  }
}
@media screen and (max-width: 320px) {
  .ConsultantCarouselCheckpoints-checkpointIconContainer {
    margin-left: -50px;
  }
}
.ConsultantCarouselCheckpoints-checkpointIcon {
  height: 19.35px;
  fill: #7ab51d;
}
@media screen and (max-width: 320px) {
  .ConsultantCarouselCheckpoints-checkpointIcon {
    height: 20px;
  }
}
.ConsultantCheckpoints-headLine {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.ConsultantCheckpoints-phoneSvg {
  font-size: 16px;
  fill: #005ea8;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.ConsultantCheckpoints-serviceHotline {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.ConsultantCheckpoints-phonenNumber {
  font-size: 16px;
}
.ConsultantCheckpoints-phoneNumberWrapper {
  margin: auto;
}
.ConsultantCheckpoints-consultantInfoWrapper {
  border-bottom: 1px, solid, #dcdcdc;
  margin-bottom: 10px;
  margin-top: -10px;
}
.InsuranceLink {
  width: 100%;
  display: flex;
  justify-content: center;
}
.InsuranceLink a {
  font-size: 12px;
  color: #005ea8;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  margin-bottom: 24px;
  text-align: left;
  width: 230px;
}
.OnboardingAdditionalDataSection-label {
  font-size: 14px;
  margin-top: 10px;
  color: #666666;
  line-height: 14px;
  margin-bottom: 7px;
}
.OnboardingAdditionalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}
.OnboardingAdditionalDataSection-chipsInputHelpText {
  top: 1px;
}
.OnboardingAdditionalDataSection {
  margin-bottom: 24px;
}
.OnboardingAdditionalDataSection > div {
  margin-bottom: 15px;
  position: relative;
}
.PublicServantPayGradeSelector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  margin-bottom: 18px;
}
.OnboardingBirthday-birthdayRow {
  margin-bottom: 24px;
}
.OnboardingCoverageDataSection-row {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}
.OnboardingCoverageDataSection-label {
  font-size: 14px;
  margin-top: 10px;
  color: #666666;
  line-height: 14px;
  margin-bottom: 7px;
}
.OnboardingCoverageDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}
.OnboardingCta {
  position: sticky;
  z-index: 2;
  display: flex;
  bottom: calc(10px + env(safe-area-inset-bottom));
}
.OnboardingCta-button {
  height: 50px;
}
.OnboardingPersonalDataSection-row {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}
.OnboardingPersonalDataSection-row .OnboardingPersonalDataSection-occupationOverlay {
  background: transparent;
  box-shadow: none;
  padding: 10px 10px 0 10px;
  border: none;
  width: 100%;
  padding: 0px;
  margin-top: 30px;
}
.OnboardingPersonalDataSection-space {
  margin-bottom: 24px;
}
.OnboardingPersonalDataSection-birthdayRow {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}
.OnboardingPersonalDataSection-label {
  font-size: 13px;
  color: #666666;
  padding-top: 9px;
  padding-left: 9px;
}
.OnboardingPersonalDataSection-recalcHintIcon {
  width: 40px;
  height: 40px;
  fill: #005ea8;
  margin-right: 15px;
  flex: 0 0 50px;
  display: flex;
}
.OnboardingPersonalDataSection-recalcHintHeadlineText {
  font-weight: bold;
}
.OnboardingPersonalDataSection-recalcHint {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 14px;
  border: 1px solid #333333;
  padding: 15px 15px;
  background-color: #ffffff;
  position: relative;
}
.OnboardingPersonalDataSection-recalcHint:after,
.OnboardingPersonalDataSection-recalcHint:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  content: " ";
  border: solid transparent;
}
.OnboardingPersonalDataSection-recalcHint:after {
  border: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  margin-left: 1px;
}
.OnboardingPersonalDataSection-recalcHint:before {
  border: 16px solid transparent;
  border-bottom: 16px solid #333333;
}
.OnboardingPersonalDataSection-recalcHintClose {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #b4b4b4;
}
.OnboardingPersonalDataSection-inputWrapper {
  background: #ffffff;
  padding-bottom: 6px;
}
.OnboardingPersonalDataSection-headlineHelpText {
  top: 4px;
  right: 10px;
  height: 20px;
  width: 20px;
}
.OnboardingPersonalDataSection-inputHelpText {
  width: 20px;
  height: 20px;
  top: 17px;
  right: 10px;
}
.OnboardingPersonalDataSection-OccupationInput .InputAutoSuggest-closeWrapper {
  top: 30px;
  right: 25px;
}
.OnboardingProgressBar {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  padding: 15px 30px;
}
.OnboardingProgressBar-step {
  color: #b4b4b4;
  flex: 1;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 26px;
  height: 58px;
}
.OnboardingProgressBar-step.OnboardingProgressBar-step--is-active {
  color: #005ea8;
}
.OnboardingProgressBar-step.OnboardingProgressBar-step--is-completed {
  color: #666666;
}
.OnboardingProgressBar-step svg {
  height: 35px;
  width: 35px;
  display: block;
  margin-bottom: 6px;
  fill: #b4b4b4;
}
.OnboardingProgressBar-step--is-active svg,
.OnboardingProgressBar-step--is-completed svg {
  fill: #005ea8;
}
.OnboardingProgressBar-stepSeparator {
  position: relative;
  width: 100%;
  border: 1px solid #b4b4b4;
  top: -10px;
}
.OnboardingProgressBar-stepSeparator.OnboardingProgressBar-stepSeparator--is-completed {
  border: 1px solid #005ea8;
}
.OnboardingProgressBar-step--is-active:after {
  position: absolute;
  bottom: -25px;
  height: 18px;
  width: 18px;
  background: #ffffff;
  content: '';
  border: 1px solid #dcdcdc;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
  z-index: 5;
}
.OnboardingProgressBar-stepLabel--inputs {
  position: absolute;
  top: 32px;
  left: -11px;
}
.OnboardingProgressBar-stepLabel--features {
  position: absolute;
  top: 32px;
  left: -22px;
}
.OnboardingProgressBar-stepLabel--reductions {
  position: absolute;
  top: 32px;
  left: -8px;
}
.OnboardingProgressBar-stepLabel--comparison {
  position: absolute;
  top: 32px;
  left: -11px;
}
.OnboardingSummarySection-header {
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.OnboardingSummarySection-header span {
  font-size: 24px;
  line-height: 28px;
  color: #005ea8;
}
.OnboardingSummarySection-header svg {
  fill: #005ea8;
  margin-top: 2px;
  width: 35px;
  height: 35px;
}
.OnboardingSummarySection-headerBorder {
  border-top: 1px solid #666666;
  margin-top: 16px;
}
.PersonalDataSection-PostalCode-input {
  display: flex;
}
.PersonalDataSection-streetInputWrapper {
  display: flex;
  justify-content: space-between;
}
.PersonalDataSection-streetInputWrapperDisabled div {
  color: #999999;
}
.PersonalDataAddress-inputDisabled input[disabled] {
  color: #999999;
}
.PersonalDataAddress-inputWrapper {
  white-space: nowrap;
  overflow: unset;
  width: 37%;
  margin-right: 7px;
}
.PersonalDataAddress-inputWrapperCity {
  width: 63%;
}
.PersonalDataSection-streetInput {
  text-overflow: ellipsis;
  position: relative;
}
.PersonalDataSection-streetName-container {
  width: 70%;
  margin-right: 7px;
}
.PersonalDataSection-number-container {
  width: 30%;
}
.PersonalDataAddressCities-selectBox {
  padding-right: 0;
  text-overflow: ellipsis;
}
.PersonalDataAddressCities-selectBox.show-asLabel {
  background: none;
  color: #333333;
}
.PersonalDataAddressCities-selectBox.is-disabled {
  border: none;
}
.PersonalDataAddressCities-selectBox:hover {
  border: none;
}
.PersonalDataSection-selectBoxContainer {
  flex: 2;
}
.PersonalDataAdress-cityPlaceholder {
  font-size: 18px;
  color: #999999;
}
.PersonalDataAddress-modalWrapper {
  white-space: initial;
}
.PersonalDataAgreementSection {
  padding: 0 15px;
  font-size: 12px;
  margin: 24px 0 40px 0;
}
.PersonalDataAgreementSection-link {
  text-decoration: underline;
}
.PersonalDataAgreementSection-newTerms {
  padding-bottom: 15px;
  line-height: 14px;
}
.PersonalDataHeaderBox {
  position: relative;
  background-color: #ffffff;
  padding: 10px 16px 15px 16px;
}
.PersonalDataHeaderBox.is-resultZero {
  padding-top: 34px;
}
.PersonalDataHeaderBox-top {
  display: flex;
  align-items: flex-end;
}
.PersonalDataHeaderBox-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}
.PersonalDataHeaderBox-left {
  flex: 30;
  flex-grow: 2;
}
.PersonalDataHeaderBox-right {
  flex: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex-grow: 1;
}
.PersonalDataHeaderBox-insuranceLogo {
  max-width: 100px;
  max-height: 30px;
  margin-bottom: 10px;
}
.PersonalDataHeaderBox-tariffName {
  font-size: 14px;
  color: #333333;
}
.PersonalDataHeaderBox-price {
  font-size: 28px;
  color: #333333;
  white-space: nowrap;
}
.PersonalDataHeaderBox-paymentPeriod {
  font-size: 14px;
  color: #333333;
  margin-right: 10px;
}
.PersonalDataHeaderBox-grade {
  margin-bottom: 0;
}
.PersonalDataHeaderBox-toggleBox {
  display: flex;
  align-items: flex-start;
}
.PersonalDataHeaderBox-panelArrow {
  width: 14px;
  height: 7px;
  fill: #005ea8;
  margin-top: 5px;
}
.PersonalDataHeaderBox-panelArrow.is-saving {
  margin-left: 10px;
}
.PersonalDataHeaderBox-panelArrow.is-open {
  transform: rotate(180deg);
}
.PersonalDataHeaderBox-savingsPriceContainer {
  display: block;
}
.PersonalDataHeaderBox-savingsPrice {
  color: #333333;
  font-size: 28px;
}
.PersonalDataHeaderBox-savingsPriceInterval {
  line-height: 14px;
  font-size: 14px;
}
.PersonalDataHeaderBox-oldPrice {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  display: flex;
  justify-content: center;
  color: #c82d2d;
}
.PersonalDataHeaderBox-tooltip {
  width: 750px;
}
.PersonalDataHeaderBox-infoContainer {
  height: 12px;
  width: 12px;
  fill: #b4b4b4;
  margin-left: 3px;
  pointer-events: auto;
  cursor: help;
}
.PersonalDataHeaderBox-campaign {
  margin-top: 20px;
}
.ComparisonHint {
  display: flex;
  background-color: #cae7a8;
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  color: #005ea8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.ComparisonHint-comparisonIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  fill: #005ea8;
}
.ComparisonHint-iconContainer {
  display: flex;
  align-items: center;
}
.InsuranceAndTariff-savingsTextPrice {
  color: #008300;
}
.InsuranceAndTariff-logoAndNameContainer {
  width: 70%;
}
.InsuranceAndTariff-insuranceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.InsuranceAndTariff-insuranceLogo {
  display: block;
  max-height: 35px;
  max-width: 55%;
}
.InsuranceAndTariff-savingsTextContainer {
  width: 80%;
  margin-left: 20%;
  margin-bottom: 5px;
}
.InsuranceAndTariff-savingsText {
  text-align: right;
  font-size: 14px;
}
.InsuranceAndTariff-tariffName {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  word-wrap: break-word;
}
.OverlayContainer {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0;
  background: #f4f4f4;
  top: 100vh;
  z-index: 110;
  transition: all 0.3s ease 0s;
}
.OverlayContainer.is-comparison {
  overflow-y: scroll !important;
}
@media screen and (max-width: 415px) {
  .OverlayContainer.is-scroll-enabled {
    overflow-y: scroll !important;
  }
}
.OverlayTopBar {
  height: 45px;
  background: #ffffff;
  border-bottom: solid 1px #dcdcdc;
  align-content: center;
  display: flex;
}
.OverlayCloseButton {
  height: 16px !important;
  width: 16px;
  margin: 15px 10px;
  fill: #015fa4;
}
.OverlayTitle {
  margin: auto -15% auto auto;
  text-align: center;
  font-size: 18px;
}
.OverlayResetButton {
  margin: auto 10px auto auto;
  font-size: 16px;
  color: #005ea8;
}
.OverlayResetButton.is-default {
  color: #999999;
}
.OverlayContainer.is-opened {
  display: inherit;
  transition: all 0.5s ease 0s;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.OverlayContainer.is-opened .OverlayTopBar {
  top: 0;
  position: sticky;
  z-index: 1;
}
.ResultComparisonTariffList-tariffItemContainer {
  display: flex;
}
.ResultComparisonTariffList-checkboxContainer {
  margin-top: 2px;
}
.ResultComparisonTariffList {
  padding: 8px 10px 20px;
  overflow: hidden;
}
.ResultComparisonTariffList-singleRow {
  position: relative;
  margin-bottom: 14px;
}
.ResultComparisonTariffList-comparisonBanner {
  margin: 0 0 10px;
  padding: 5px 5px 5px 0;
  font-size: 16px;
}
.ResultComparisonTariffList-checkbox {
  -webkit-tap-highlight-color: transparent;
  width: 40px;
  height: 100%;
}
.ResultComparisonTariffList-checkbox .Checkbox-label {
  align-self: flex-end;
}
.ResultComparisonTariffList-checkbox .Checkbox-icon {
  width: 20px;
  height: 20px;
  margin-top: 0;
  flex: none;
}
.ResultPage-FilterOptions {
  margin: 27px 10px 268px 10px;
}
.ResultPage-FilterOptions.RadioButton-caption {
  font-size: 16px !important;
}
.ResultFiltersOverlay-label {
  font-size: 18px;
  color: #666666;
  line-height: 18px;
}
.ResultFiltersOverlay-row {
  position: relative;
}
.ResultFiltersOverlay-check24TariffGradeContainer {
  width: 90%;
}
.ResultFiltersOverlay-filterChip {
  min-height: 40px;
  padding: 0 10px;
  max-width: 290px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 320px) {
  .ResultFiltersOverlay-filterChip {
    max-width: 180px;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .ResultFiltersOverlay-filterChip {
    max-width: 245px;
  }
}
.ResultFiltersOverlay-checkBoxQuestionMark {
  top: 13px;
}
.ResultFiltersOverlay-dynamicPremiumFilterContainer {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}
.ResultFiltersOverlay-dynamicPremiumFilterContainer .FirstViewFilterChips {
  margin-top: 10px;
  white-space: nowrap;
}
.ResultFiltersOverlay-checkBoxQuestionMark {
  top: 20px;
}
.ResultFiltersOverlay-dynamicPremiumFilter .ResultFiltersOverlay-checkBoxQuestionMark,
.ResultFiltersOverlay-check24TariffGrade .ResultFiltersOverlay-checkBoxQuestionMark {
  top: 38px;
}
.ResultFiltersOverlay-dynamicPremiumFilter {
  margin-top: 40px;
  margin-bottom: 40px;
}
.ResultPage-SortingOptions {
  background: #ffffff;
  margin: 27px 10px 268px 10px;
}
.ResultPage-SortingOptions.RadioButton-caption {
  font-size: 16px !important;
}
.ResultSummary {
  text-align: center;
  font-size: 14px;
  color: #333333;
}
.ResultSummary-priceRange {
  color: #333333;
}
.ResultTariffList {
  padding: 8px 10px 0;
  overflow: hidden;
}
.ResultTariffList-singleRow {
  position: relative;
  margin-bottom: 14px;
}
.ResultTariffList-singleRow:last-child {
  margin-bottom: 0;
}
.Tabs-tabContainer {
  top: 0;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  position: sticky;
  z-index: 1;
}
.TariffInfoBox {
  border: 1px solid #dcdcdc;
  border-top: none;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
}
.TariffInfoBox.is-detailsPage {
  padding: 0;
  border: none;
}
.TariffInfoBox-container {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
}
.TariffInfoBox-container.is-last {
  border: none;
}
.TariffInfoBox-featureIcon {
  margin-right: 6px;
}
.TariffInfoBox-featureIcon > svg {
  width: 20px;
  height: 20px;
}
.TariffInfoBox-feature {
  display: flex;
}
.TariffInfoBox-featureText,
.TariffInfoBox-customerRatingsText {
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;
}
@media screen and (max-width: 320px) {
  .TariffInfoBox-featureText,
  .TariffInfoBox-customerRatingsText {
    font-size: 14px;
  }
}
.TariffInfoBox-customerRatingsText {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 350px) {
  .TariffInfoBox-customerRatingsText {
    display: block;
  }
}
.TariffInfoBox-customerRatingsText .TariffInfoBox-ratingStarsContainer {
  display: flex;
  font-size: 14px;
}
.TariffInfoBox-customerRatingsText .TariffInfoBox-ratingStarsContainer .EfeedbackRatingStars {
  margin-top: 2px;
}
.TariffInfoBox-featureHelpText {
  font-size: 14px;
  color: #333333;
}
.TariffInfoBox-promotionHelpText {
  margin-left: 26px;
  font-size: 14px;
  color: #333333;
}
.TariffInfoBox-promotionHelpTextHeadline {
  font-weight: bold;
  margin-left: 26px;
  font-size: 14px;
  color: #333333;
}
.TariffInfoBox-promotionName {
  background-color: #008300;
  color: #ffffff;
  padding: 0 3px;
  height: 20px;
  font-size: 15px;
  border-radius: 2px;
  margin-right: 6px;
}
.TariffInfoBox-promotionDescription {
  font-size: 16px;
  margin-bottom: 10px;
  color: #008300;
}
.TariffInfoBox-promotionDescriptionDuv {
  font-size: 14px;
  margin-bottom: 10px;
  color: #008300;
}
.TariffInfoBox-feature,
.TariffInfoBox-promotion {
  display: flex;
  position: relative;
}
.TariffInfoBox-closeContainer {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  padding-right: 20px;
  position: relative;
  padding-left: 10px;
  color: #005ea8;
  display: flex;
  align-items: center;
}
.TariffInfoBox-arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  right: 5px;
  top: 5px;
  fill: #005ea8;
}
.TariffListItemBullets-featureTooltip,
.TariffListItemBullets-promotionTooltip {
  width: 290px;
}
.TariffListItemBullets-featureTooltip:after,
.TariffListItemBullets-promotionTooltip:after {
  left: 30% !important;
}
.TariffListItemBullets-featureTooltip:before,
.TariffListItemBullets-promotionTooltip:before {
  left: 30% !important;
}
.TariffListItemBullets-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}
.TariffListItemBullets-container {
  font-size: 12px;
  margin-right: 10px;
}
.TariffListItemBullets-container.PersonalDataHeaderBox-bulletsContainer {
  padding-bottom: 0;
}
@media screen and (max-width: 320px) {
  .TariffListItemBullets-container.is-comparison {
    width: 180px;
  }
}
.TariffListItemBullets-feature {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.TariffListItemBullets-feature:last-child {
  margin-bottom: 0px;
}
.TariffListItemBullets-promotion {
  margin-top: 5px;
  display: flex;
  position: relative;
}
.TariffListItemBullets-featureIcon {
  margin-right: 6px;
}
.TariffListItemBullets-featureIcon > svg {
  width: 20px;
  height: 20px;
}
.TariffListItemBullets-promotionName {
  background-color: #008300;
  color: #ffffff;
  padding: 0 3px;
  height: 16px;
  font-size: 11px;
  border-radius: 2px;
  margin-right: 6px;
}
.TariffListItemBullets-promotionDescription {
  color: #008300;
}
.ResultTariffListItemBullets-labelWrapper {
  display: flex;
  margin-top: 5px;
}
.ResultTariffListItemBullets-labelWrapperDuv {
  display: flex;
  margin-top: 10px;
}
.ResultTariffListItemBullets-label {
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 15px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 20px;
  line-height: 19px;
}
@media screen and (max-width: 320px) {
  .ResultTariffListItemBullets-label {
    font-size: 12px;
  }
}
.ResultTariffListItemBullets-labelComment {
  font-size: 16px;
  line-height: 19px;
  padding-left: 6px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
@media screen and (max-width: 320px) {
  .ResultTariffListItemBullets-labelComment {
    font-size: 14px;
  }
}
.ResultTariffListItemBullets-labelCommentDuv {
  font-size: 14px;
  line-height: 19px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 320px) {
  .ResultTariffListItemBullets-labelCommentDuv {
    font-size: 12px;
  }
}
.TariffListItemBullets-featureText {
  font-size: 16px;
}
@media screen and (max-width: 320px) {
  .TariffListItemBullets-featureText {
    font-size: 14px;
  }
}
.TariffListItemBullets-bottom {
  position: relative;
  margin-right: -10px;
}
.TariffListItemBullets-pointsIcon {
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.TariffListItemPrice-tooltip {
  width: 750px;
}
.TariffListItemPrice-backdatedPriceContainer {
  display: block;
  margin-bottom: 15px;
}
.TariffListItemPrice-savingsPrice {
  color: #333333;
  font-size: 28px;
  text-align: right;
  flex-basis: auto;
}
.TariffListItemPrice-price {
  color: #333333;
  font-size: 28px;
  line-height: 22px;
  text-align: right;
  flex-basis: auto;
  white-space: nowrap;
}
@media screen and (max-width: 320px) {
  .TariffListItemPrice-price {
    font-size: 26px;
  }
}
.TariffListItemPrice-currentPrice {
  height: 34px;
}
.TariffListItemPrice-infoContainer {
  width: 12px;
  height: 12px;
  fill: #b4b4b4;
  margin-left: 3px;
}
.TariffListItemPrice-oldPrice {
  color: #c82d2d;
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  font-weight: 400;
  text-align: right;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row-reverse;
}
.TariffListItemPrice-priceInterval {
  font-size: 14px;
  font-weight: normal;
  float: right;
  text-align: right;
  color: #333333;
  height: 14px;
  margin-top: 3px;
}
.TariffListItemPrice-priceContainer {
  line-height: 28px;
  height: 50px;
}
.TariffListItemPrice-priceContainer .CampaignBanner-container {
  width: 102px;
  background-color: #febb00;
  padding: 0px;
}
.TariffListItem-wrapper {
  width: 100%;
}
.TariffListItem-wrapper.is-comparison {
  min-width: 97%;
}
@media screen and (min-width: 450px) {
  .TariffListItem-wrapper.is-comparison {
    min-width: inherit;
  }
}
.TariffListItem {
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 8px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.TariffListItem.is-extraResultZero {
  background-color: #ecf7fd;
}
.TariffListItem-index {
  color: #b4b4b4;
  font-size: 14px;
  line-height: 14px;
}
.TariffListItem-top {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.TariffListItem-bottom {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.TariffListItem-bottom.is-duv {
  display: block;
}
.TariffListItem-footer {
  margin-top: 20px;
  display: flex;
}
.TariffListItem-tariffInfoBoxHeader {
  flex: 1;
  line-height: 14px;
  font-size: 14px;
  position: relative;
  color: #005ea8;
  display: flex;
  align-items: center;
}
.TariffListItem-linkText {
  display: flex;
  align-items: center;
  max-width: 85%;
  font-size: 16px;
}
@media screen and (max-width: 320px) {
  .TariffListItem-linkText {
    font-size: 14px;
  }
}
.TariffListItem-tariffInfoBoxArrow {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  fill: #005ea8;
}
.TariffListItem-tariffInfoBoxArrow.is-open {
  transform: rotate(180deg);
}
.TariffListItem-container {
  display: flex;
}
.TariffListItem-content {
  width: 100%;
}
.TariffListItem-detailsWrapper {
  -webkit-tap-highlight-color: transparent;
}
.TariffListItem-CampaignBannerContainer {
  margin-right: 0;
  margin-left: auto;
}
.TariffListItem-bottom-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.TariffListItem-year-label .CampaignBanner-container {
  margin-bottom: 10px;
  background-color: #febb00;
}
.TariffListItem-header {
  display: flex;
  gap: 5px;
  margin-top: -8px;
  margin-left: -15px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
}
.TariffListItem-banners {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.TariffListItem-wishlistHeart {
  margin-left: auto;
}
.RolandPromotionBanner-PersonalPageBannerContainer {
  margin-top: 10px;
}
.RolandPromotionBanner-Container {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.RolandPromotionBanner-BannerBody {
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  white-space: nowrap;
}
.RolandPromotionBanner-bannerCorner {
  position: absolute;
  left: 100%;
  top: 0;
  border-right: 10px solid transparent;
  border-top: 21px solid #7ab51d;
}
.RolandPromotionBanner-BannerText {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0px 5px;
}
.RolandPromotionBanner-BannerText > span {
  margin-top: 1px;
}
.RolandPromotionBanner-infoIcon {
  fill: #ffffff;
  height: 13px;
  width: 13px;
}
.RolandPromotionBanner-TextContainer {
  display: flex;
  flex: 6;
  flex-direction: column;
  justify-content: center;
  padding-top: 35px;
  white-space: nowrap;
  margin-left: 15px;
}
.RolandPromotionBanner-Text {
  color: #999999;
  margin-top: -2px;
}
@media screen and (max-width: 320px) {
  .RolandPromotionBanner-Text,
  .RolandPromotionBanner-Title {
    font-size: 13px;
  }
}
.RolandPromotionBanner-Title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
@media screen and (max-width: 320px) {
  .RolandPromotionBanner-Title {
    font-size: 13px;
  }
}
.RolandPromotionBanner-Logo {
  flex: 1;
  margin-top: 28px;
  margin-left: 14px;
}
.RolandPromotionBanner-Logo > svg {
  width: 26px;
  height: 35px;
}
.RolandPromotionBanner-Logo > svg > .cls-1 {
  fill: #0271c2;
}
.RolandPromotionBanner-SignetContainer {
  flex: 1;
}
.RolandPromotionBanner-SignetContainer > svg {
  height: 76px;
  width: 76px;
  padding: 10px;
}
@media screen and (max-width: 320px) {
  .RolandPromotionBanner-SignetContainer > svg {
    height: 85px;
    width: 63px;
    margin-left: -6px;
  }
}
.RolandPromotionBanner-modalContent {
  white-space: normal;
}
.RolandPromotionBannerModal-helpTextTitle {
  font-weight: 700;
  color: #063773;
}
.InquiryButton-container {
  margin: 15px 10px 10px 10px;
}
.InquiryButton-hint {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  color: #333333;
}
.InquiryButton-checkIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: #7ab51d;
}
.ThankyouPage > .CseTeasers {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}
.CseTeasers-headline {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  margin: 0 0 10px 0;
}
.CseTeasers-container {
  background-color: #fff;
  margin: 0 -14px;
  padding: 0;
  font-size: 11px;
  line-height: 16px;
  color: #666;
}
.CseTeasers-container fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.ThankyouInfo {
  margin-bottom: 10px;
  font-size: 16px;
}
.ThankyouInfo-headline {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.ThankyouInfo-checkSvg {
  width: 28px;
  height: 22px;
  margin-right: 15px;
  fill: #7ab51d;
}
.ThankyouInfo-headlineText {
  color: #333333;
  font-size: 20px;
}
.ThankyouInfo-emailConfirmation {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.ThankyouInfo-tariffInfo {
  margin-bottom: 25px;
}
.ThankyouInfo-tariffInfoRow {
  display: flex;
}
.ThankyouInfo-tariffInfoLabel {
  width: 125px;
}
.ThankyouInfo-tariffInfoDescription {
  font-weight: 700;
}
.ThankyouSteps {
  font-size: 16px;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.ThankyouSteps-headline {
  color: #333333;
  font-size: 20px;
}
.ThankyouSteps-numberSvg {
  height: 25px;
  width: 25px;
  margin-right: 5px;
  fill: #666666;
}
.ThankyouSteps-item {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ThankyouSteps-item:last-child {
  margin-bottom: 0;
}
.ThankyouSteps-stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.ThankyouSteps-headerText {
  font-weight: 700;
}
.ThankyouSteps-stepText {
  margin-left: 30px;
}
.AcceptText-QuestionMark {
  fill: #b4b4b4;
}
.AcceptText-row {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
}
.AcceptText-row svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.AcceptText-row .AcceptText-text {
  font-size: 16px;
  color: #333333;
}
.AcceptText-row .AcceptText-feature {
  font-size: 14px;
}
.VersicherungPlusSignup-option {
  font-size: 16px;
}
.VersicherungPlusSignup-title {
  font-size: 18px;
}
.VersicherungPlusSignup-pointsText {
  font-size: 14px;
  color: #666666;
}
.ErrorPage {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ErrorPage-content {
  margin: 25px 15px 20px 15px;
}
.ErrorPage-header {
  font-size: 16px;
  color: #333333;
  margin: 0;
}
.ErrorPage-text {
  font-size: 14px;
  color: #333333;
  margin-bottom: 40px;
}
.ErrorPage-image {
  display: block;
  max-width: 100%;
  height: auto;
}
.AppointmentPage {
  padding-top: 10px;
  background-color: #ffffff;
  padding-bottom: 10px;
}
.AppointmentPage > div,
.AppointmentPage span {
  padding-right: 10px;
  padding-left: 10px;
}
.AppointmentPage-consultantBoxWrapper {
  margin-bottom: 15px;
}
.ComparisonPage-headerButtonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  color: #005ea8;
  background-color: #ffffff;
  font-size: 13px;
  align-items: center;
}
.ComparisonPage-backButtonArrow {
  height: 6px;
  width: 11px;
  margin-right: 5px;
  transform: rotate(-90deg);
  fill: #005ea8;
}
.ComparisonPage-backButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ComparisonPage {
  background: #f4f4f4;
}
.InfoTariffNotesPage {
  overflow-x: scroll;
}
.InsuranceList-headline {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.InsuranceList-insurances {
  padding: 15px;
}
.InsuranceList-subHeadline {
  padding-top: 10px;
  font-size: 16px;
}
.InsuranceList-link {
  display: flex;
  padding: 0;
  align-items: center;
  color: #005ea8;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.InsuranceList-dateOfValidity {
  color: #b4b4b4;
}
.InsuranceList-linkPointer {
  transform: rotate(90deg);
  width: 17px;
  height: 9px;
  margin-right: 10px;
  fill: #005ea8;
}
.InsuranceList-separatorBar {
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
  border-width: 2px;
}
.InsuranceList-headlineInfo {
  color: #333333;
}
.InsuranceList-pdfIcon {
  fill: #005ea8;
  height: 22px;
  width: 22px;
  margin-right: 5px;
}
.InsuranceList-pdfLink {
  display: flex;
  align-items: self-end;
  margin: 10px 0;
}
.OnboardingAdditionalData-nextButton {
  height: 50px;
  margin-bottom: 20px;
}
.OnboardingAdditionalData-content {
  display: block;
  padding: 15px 15px 0;
  position: relative;
  border-top: 1px solid #dcdcdc;
}
.OnboardingAdditionalData-content .OnboardingAdditionalData-campaignBanner {
  margin-top: 20px;
}
.OnboardingCoverageData-nextButton {
  height: 50px;
  margin-bottom: 20px;
}
.OnboardingCoverageData-content {
  display: block;
  padding: 15px 15px 0;
  position: relative;
  border-top: 1px solid #dcdcdc;
}
.OnboardingCoverageData-content .OnboardingCoverageData-campaignBanner {
  margin-top: 20px;
}
.OnboardingPersonalData-nextButton {
  height: 50px;
  margin-bottom: 20px;
}
.OnboardingPersonalData-content {
  display: block;
  padding: 15px 15px 0;
  position: relative;
  border-top: 1px solid #dcdcdc;
}
.OnboardingPersonalData-content .OnboardingPersonalData-campaignBanner {
  margin-top: 20px;
}
.OnboardingPersonalData-header {
  font-size: 20px;
  margin-bottom: 10px;
  color: #666666;
}
.OnboardingPersonalData-ConsultantCheckpoints {
  margin-top: 24px;
}
.OnboardingSummary {
  color: #333333;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 5px 15px 15px 15px;
  position: relative;
  border-top: 1px solid #dcdcdc;
}
.OnboardingSummary .OnboardingCoverageDataSection-row,
.OnboardingSummary .OnboardingPersonalDataSection-space,
.OnboardingSummary .OnboardingAdditionalDataSection {
  margin-bottom: 16px;
}
.OnboardingSummary-sectionHeader {
  padding-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #b4b4b4;
}
.OnboardingSummary-sectionContent > div {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 16px;
  color: #666666;
}
.OnboardingSummary-sectionHeader.OnboardingSummary-sectionHeader--hasNoBorder {
  border: none;
}
.OnboardingSummary-sectionHeaderTitle {
  font-size: 24px;
  line-height: 28px;
  color: #005ea8;
}
.OnboardingSummary-sectionHeaderIcon {
  margin-top: 2px;
  width: 20px;
  height: 20px;
}
.OnboardingSummary-header {
  font-size: 20px;
  color: #005ea8;
}
.OnboardingSummary-actionButton {
  height: 50px;
  position: sticky;
  bottom: calc(10px + env(safe-area-inset-bottom));
  z-index: 2;
}
.OnboardingSummary-sectionContentWithSpace {
  margin-bottom: 24px;
}
.Onboarding-header {
  font-size: 24px;
  color: #333333;
  margin-bottom: 16px;
}
.PersonalDataPage {
  color: #333333;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
}
.PersonalDataPage-headlineBox {
  padding: 10px 15px 13px;
}
.PersonalDataPage-section {
  margin-top: 11px;
}
.PersonalDataPage-hint {
  display: flex;
  margin-top: 10px;
  font-size: 16px;
  color: #333333;
  line-height: 18px;
}
.PersonalDataPage-checkHint {
  width: 25px;
  min-width: 25px;
  height: 20px;
  margin-right: 10px;
  fill: #7ab51d;
}
.PersonalDataPage-headline {
  color: #666666;
  font-size: 20px;
  margin-bottom: 24px;
}
.PersonalDataPage-ctaSection {
  display: flex;
  margin: 0 15px 40px;
}
.PersonalDataPage-errorSection {
  color: #c82d2d;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 5px;
}
.PersonalDataPage-genericError {
  font-size: 16px;
  margin-top: 7px;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  color: #c82d2d;
}
.PersonalDataPage-inquiryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.PersonalDataPage-genericErrorIcon > svg {
  width: 17px;
  height: 17px;
  margin-bottom: -2px;
  margin-right: 6px;
}
.PersonalDataPage-inquiryButtonHint {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
  color: #7ab51d;
}
.PersonalDataPage-checkIcon {
  width: 12px;
  height: 10px;
  margin-right: 6px;
  fill: #7ab51d;
}
.PersonalDataPage-inquiryButton {
  margin-bottom: 4px;
}
.PersonalDataPage-agreementSection {
  margin-top: 40px;
}
.PersonalDataPage-backButton {
  font-family: Arial, serif;
  font-size: 13px;
  color: #005ea8;
  margin-top: 20px;
}
.PersonalDataPage-backButtonArrow {
  height: 6px;
  width: 11px;
  transform: rotate(-90deg);
  fill: #005ea8;
}
.PersonalLogin-iframe {
  display: block;
}
.PersonalLogin-separation-line {
  margin-bottom: 0;
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 0;
  margin-top: 1px;
}
.PersonalLogin-separation-line:after {
  content: "";
  display: inline-block;
  width: 100%;
  border-top: 1px solid #dcdcdc;
}
.ResultPage {
  color: #333333;
  font-family: Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  background: #f4f4f4;
}
.ResultPage-insuranceListLink {
  margin-left: 228px;
}
.ResultPage-infoTariffNotes {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
}
.ResultPage-insuranceLink {
  padding-left: 10px;
  padding-right: 10px;
}
.ResultPage-savingsCampaignBanner {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.ThankyouErrorPage {
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
  height: 100%;
}
.ThankyouErrorPage-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100%;
}
.ThankyouErrorPage-headline {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.ThankyouErrorPage-crossSvg {
  width: 28px;
  height: 22px;
  margin-right: 10px;
  fill: #7ab51d;
}
.ThankyouErrorPage-headlineText {
  color: #333333;
  font-size: 20px;
}
.ThankyouErrorPage-infoMessage {
  font-size: 14px;
  color: #333333;
}
.ThankyouErrorPage-text {
  font-size: 16px;
  color: #333333;
  margin-top: 30px;
}
.ThankyouErrorPage-phoneNumberContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
}
.ThankyouErrorPage-phoneSvg {
  fill: #005ea8;
  height: 27px;
  width: 27px;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 2px;
}
.ThankyouErrorPage-phoneNumber {
  color: #005ea8;
  font-size: 20px;
}
.ThankyouErrorPage-workingTimes {
  color: #333333;
  font-size: 16px;
}
.ThankyouErrorPage-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.ThankyouErrorPage-backLink {
  font-size: 16px;
  color: #333333;
}
.ThankyouPage {
  font-size: 14px;
  background: #f4f4f4;
}
.ThankyouPage > div {
  padding: 20px 15px;
  background: #ffffff;
}
.ThankyouPage > .C24Points-mainWrapper {
  margin: -25px -10px 10px;
}
.ThankyouPage .ThankyouPage-C24Tag {
  display: block;
}
.ThankyouPage .ThankyouAnotherProducts-container {
  padding-top: 0px;
}
.ThankyouPage-otherProductsContainer {
  padding-bottom: 40px;
  margin-top: -10px;
}
.ThankyouPage-otherProductsButton {
  background: #f4f4f4;
  font-weight: normal;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0);
  color: #666666;
}
.ThankyouPage-otherProductsButton:active,
.ThankyouPage-otherProductsButton:hover {
  background: #f4f4f4;
  border: 1px solid #ccc;
  color: #dcdcdc;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0);
}
.CashbackThankyouPage {
  margin: 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;
}
.CashbackThankyouPage .CashbackThankyouPage-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 16px;
  color: #333333;
  font-weight: 400;
  line-height: 30px;
}
.CashbackThankyouPage .CashbackThankyouPage-svg {
  fill: #7ab51d;
  height: 20px;
  width: 25px;
  margin-top: 6px;
}
.CashbackThankyouPage .CashbackThankyouPage-header {
  font-size: 20px;
  color: #333333;
}
.CashbackThankyouPage .CashbackThankyouPage-body {
  font-size: 16px;
  padding-left: 36px;
  color: #333333;
}
.BenefitAgeLimit-ctaButton {
  margin-top: 16px;
}
.BenefitAgeLimit-form {
  margin-top: 16px;
}
.BenefitAmount-title {
  font-size: 24px;
  color: #333333;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.BenefitAmount-helpLink-container {
  display: flex;
  justify-content: flex-end;
}
.BenefitAmount-helpLink {
  font-family: Arial;
  font-size: 16px;
  color: #005ea8;
  line-height: 16px;
  text-align: right;
  margin-top: 10px;
}
.monthlyInput-Component {
  margin-bottom: 16px;
  margin-top: 16px;
}
.benefitAmount-Component {
  margin-bottom: 16px;
  margin-top: 16px;
}
.c24-bu-onboarding-education-dropdown {
  margin-top: 16px;
}
.FamilyStatus-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 14px;
}
.FamilyStatus-familyStatuses {
  margin-top: 16px;
}
.FractionOfficeWorkPage {
  margin-top: 16px;
}
.FractionOfficeWorkPage-link {
  font-size: 12px;
  color: #005ea8;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
}
.FractionOfficeWorkPage-ctaButton {
  margin-top: 16px;
}
.IndustryPage {
  margin-top: 16px;
}
.IndustryPage-participatedInsurances {
  margin: 0 45px 20px;
}
.JobSituation-jobSituations {
  margin-top: 10px;
}
.JobSituation-title {
  font-size: 24px;
  color: #333333;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.Occupation-occupations {
  margin-top: 16px;
}
.SalutationBirthday-title {
  font-size: 24px;
  color: #333333;
  line-height: 28px;
  margin-top: 44px;
  margin-bottom: 16px;
}
.SalutationBirthday-link {
  font-size: 12px;
  color: #005ea8;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
.SalutationEducation-title {
  font-size: 24px;
  color: #333333;
  line-height: 28px;
  margin-top: 44px;
  margin-bottom: 16px;
}
.SalutationEducation-link {
  font-size: 12px;
  color: #005ea8;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
.SmokingPage {
  margin-top: 16px;
}
.SmokingPage > div {
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}
.SmokingPage-title {
  font-size: 24px;
  color: #333333;
  line-height: 28px;
  margin-bottom: 11px;
}
.SmokingPage-link {
  font-size: 12px;
  color: #005ea8;
  line-height: 18px;
  margin-top: 24px;
  display: block;
  text-align: center;
  width: 100%;
}
.SmokingPage-ctaButton {
  margin-top: 16px;
}
.onboardingStaffResponsibilityDropdown {
  margin-top: 16px;
}
.Resubscribe-container {
  margin: 32px 9px 0 9px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.Resubscribe-headline {
  display: flex;
  flex: 1;
}
.Resubscribe-checkSvg {
  width: 25px;
  height: 20px;
  margin: 5px 10px 0 3px;
  fill: #008300;
}
.Resubscribe-headlineText {
  font-size: 20px;
}
.Unsubscribe-container {
  margin: 17px 9px 0 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.Unsubscribe-headline {
  display: flex;
  align-items: center;
  margin: 15px 0 25px 0;
}
.Unsubscribe-checkSvg {
  width: 25px;
  height: 20px;
  margin-right: 15px;
  fill: #008300;
}
.Unsubscribe-headlineText {
  font-size: 20px;
}
.Unsubscribe-text {
  font-size: 16px;
  margin-bottom: 20px;
  flex: 1;
}
.check-box_CheckBox__Mf0hn {
  font-size: 14px;
  font-family: Verdana;
  color: #333333;
  display: flex;
  align-items: center;
  width: 412px;
  height: 47px;
  box-sizing: border-box;
}
.check-box_CheckBox__Mf0hn.check-box_is-Disabled__kD8ic {
  color: #999999;
}
.check-box_CheckBox-error__Kgz38 {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.check-box_CheckBox-markBorder__8da2Q {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid;
  border-color: #b4b4b4;
  margin-right: 16px;
}
.check-box_CheckBox-markBorder__8da2Q.check-box_is-Selected__4TA34 {
  border-color: #005ea8;
}
.check-box_CheckBox-markBorder__8da2Q:hover {
  border-color: #333333;
}
.check-box_CheckBox-markBorder__8da2Q.check-box_is-Error__jpOP9 {
  border-color: #c82d2d;
}
.check-box_CheckBox-markBorder__8da2Q.check-box_is-Disabled__kD8ic {
  border-color: #999999;
  background-color: #fafafa;
}
.check-box_Checkbox-svgMark__t96z0 {
  fill: #005ea8;
}
.check-box_Checkbox-svgMark__t96z0.check-box_is-Error__jpOP9 {
  fill: #c82d2d;
}
.check-box_Checkbox-svgMark__t96z0.check-box_is-Disabled__kD8ic {
  fill: #999999;
}
.validation-bar_ValidationBar-bar__k3Xab {
  position: absolute;
  left: 0;
  transition-property: width;
  transition-duration: 0.25s;
  width: 0%;
  height: 4px;
  bottom: 0px;
  border-bottom: solid 2px;
  border-bottom-left-radius: 130px;
  border-bottom-right-radius: 130px;
}
.validation-bar_ValidationBar-bar__k3Xab.validation-bar_is-error__OYBtE {
  border-color: #c82d2d;
  width: 100%;
}
.validation-bar_ValidationBar-bar__k3Xab.validation-bar_is-valid__5PUwY {
  border-color: #7ab51d;
  width: 100%;
}
.suggestion_Suggestion__M3G2A {
  font-size: 14px;
  font-family: Verdana;
  color: #333333;
}
.suggestion_Suggestion-match__H2jFY {
  font-size: 14px;
  font-family: Verdana;
  color: #333333;
  font-weight: bold;
}
.suggestion-list_SuggestionContainer__7pJBa {
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 1px 10px 0px rgba(51, 51, 51, 0.16);
  max-height: 174px;
  left: 0;
  right: 0;
  top: 50px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #b4b4b4;
}
.input-auto-suggest_InputAutoSuggest-input__rshVR {
  font-size: 14px;
  font-family: Verdana;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  width: 100%;
  color: #333333;
}
.input-auto-suggest_InputAutoSuggest__GTEEF {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 47px;
  box-sizing: border-box;
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 5px;
  border-color: #dcdcdc;
  background: #ffffff;
}
.input-auto-suggest_InputAutoSuggest__GTEEF ::placeholder {
  color: #999999;
}
.input-auto-suggest_InputAutoSuggest__GTEEF:hover {
  border-color: #333333;
}
.input-auto-suggest_InputAutoSuggest__GTEEF.input-auto-suggest_is-Active__X-oyp {
  border-color: #005ea8;
}
.input-auto-suggest_InputAutoSuggest__GTEEF.input-auto-suggest_is-Error__vQd7o {
  border-color: #c82d2d;
}
.input-auto-suggest_InputAutoSuggest__GTEEF.input-auto-suggest_has-validationBar__BvhVz {
  border-bottom: none;
}
.input-auto-suggest_InputAutoSuggest__GTEEF.input-auto-suggest_is-Disabled__vr5au {
  border-color: #dcdcdc;
  background-color: #fafafa;
}
.input-auto-suggest_InputAutoSuggest__GTEEF .input-auto-suggest_ValidationBar-bar__Ok5p8 {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.input-auto-suggest_InputAutoSuggest-label__IRyvr {
  font-size: 12px;
  font-family: Verdana;
  color: #999999;
}
.input-auto-suggest_InputAutoSuggest-label__IRyvr.input-auto-suggest_is-Active__X-oyp {
  color: #333333;
}
.input-auto-suggest_InputAutoSuggest-label__IRyvr.input-auto-suggest_is-Error__vQd7o {
  color: #c82d2d;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__aUJ5H li {
  list-style-type: none;
  min-height: 29px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  line-height: 1.3;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__aUJ5H li:hover,
.input-auto-suggest_InputAutoSuggest-suggestionContainer__aUJ5H li[aria-selected='true'] {
  background-color: #efeff4;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__aUJ5H ul {
  padding: 0;
  margin: 0;
}
.input-auto-suggest_InputAutoSuggest-error__f9fR2 {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.radio-button_RadioButton__0jFmS {
  border: none;
  background-color: transparent;
  padding: 0 24px;
  margin: 8px 0;
  width: 50%;
  stroke: #b4b4b4;
  fill: #b4b4b4;
}
.radio-button_RadioButton__0jFmS:not(:first-child) {
  border-left: 1px solid #dcdcdc;
}
.radio-button_RadioButton__0jFmS:not(:first-child).radio-button_is-multiLine__vVPA- {
  border-left: none;
  border-top: 1px solid #dcdcdc;
}
.radio-button_RadioButton__0jFmS.radio-button_is-multiLine__vVPA- {
  height: 47px;
  margin: 0 24px;
  padding: 8px 0;
  width: auto;
}
.radio-button_RadioButton__0jFmS:hover {
  stroke: #333333;
  fill: #333333;
}
.radio-button_RadioButton__0jFmS.radio-button_is-selected__qq44C {
  stroke: #005ea8;
  fill: #005ea8;
}
.radio-button_RadioButton__0jFmS.radio-button_is-disabled__BCQsf {
  stroke: #999999;
  fill: #999999;
}
.radio-button_RadioButton__0jFmS.radio-button_is-error__m76dc {
  stroke: #c82d2d;
  fill: #c82d2d;
}
.radio-button_RadioButton-contentWrapper__3rd5g {
  display: flex;
  align-items: center;
  font-family: Verdana;
  font-size: 14px;
  color: #333333;
}
.radio-button_RadioButton-svgIcon__DzvaC {
  margin: -4px;
  height: 28px;
  width: 28px;
  margin-right: 4px;
}
.radio-button-group_RadioButtonGroup__GHXaC {
  width: 410px;
  height: 47px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid;
  border-color: #dcdcdc;
  display: flex;
  justify-content: space-around;
}
.radio-button-group_RadioButtonGroup__GHXaC.radio-button-group_is-multiLine__oOf06 {
  height: auto;
  flex-direction: column;
}
.radio-button-group_RadioButtonGroup__GHXaC:hover {
  border-color: #333333;
}
.radio-button-group_RadioButtonGroup__GHXaC.radio-button-group_is-disabled__Vat3C {
  background-color: #fafafa;
  border-color: #dcdcdc;
}
.radio-button-group_RadioButtonGroup__GHXaC.radio-button-group_is-error__qzO7e {
  border-color: #c82d2d;
}
.radio-button-group_RadioButtonGroup-error__Y8EV8 {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.select-box-option_SelectBoxOption__pPagW {
  color: #333333;
  pointer-events: none;
}
.select-box_SelectBox__rRyYG {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 47px;
  box-sizing: border-box;
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 5px;
  border-color: #dcdcdc;
  overflow: hidden;
}
.select-box_SelectBox__rRyYG:hover {
  border-color: #333333;
}
.select-box_SelectBox__rRyYG.select-box_is-Active__y0rce {
  border-color: #005ea8;
}
.select-box_SelectBox__rRyYG.select-box_is-Active__y0rce:before {
  content: '';
}
.select-box_SelectBox__rRyYG.select-box_is-Error__HJ-Km {
  border-color: #c82d2d;
}
.select-box_SelectBox__rRyYG.select-box_is-Disabled__fLQ3e {
  border-color: #dcdcdc;
  background-color: #fafafa;
}
.select-box_SelectBox__rRyYG.select-box_has-validationBar__ACalX {
  border-bottom: none;
}
.select-box_SelectBox-select-arrow__6-JbJ {
  width: 14px;
  position: absolute;
  right: 20px;
  fill: #999999;
  pointer-events: none;
}
.select-box_SelectBox-select__9fXiU {
  font-size: 14px;
  font-family: Verdana;
  border: none;
  margin: 0 -16px;
  padding: 0 16px;
  outline: none;
  appearance: none;
  background-color: transparent;
  color: #333333;
}
.select-box_SelectBox-label__NxZ8t {
  font-size: 12px;
  font-family: Verdana;
  color: #999999;
}
.select-box_SelectBox-label__NxZ8t.select-box_is-Active__y0rce {
  color: #333333;
}
.select-box_SelectBox-label__NxZ8t.select-box_is-Error__HJ-Km {
  color: #c82d2d;
}
.select-box_SelectBox-error__kMBbw {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.form-input_FormInput__NLySr {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 47px;
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 5px;
  box-sizing: border-box;
  border-color: #dcdcdc;
}
.form-input_FormInput__NLySr ::placeholder {
  color: #999999;
}
.form-input_FormInput__NLySr.form-input_is-Currency__ayRHg:before {
  position: absolute;
  top: 22px;
  content: "€";
  left: 17px;
  font-size: 14px;
  font-family: Verdana;
  color: #333333;
}
.form-input_FormInput__NLySr.form-input_is-Currency__ayRHg.form-input_is-Empty__YWMQj:before {
  content: "";
}
.form-input_FormInput__NLySr:hover {
  border-color: #333333;
}
.form-input_FormInput__NLySr.form-input_is-Active__6CHlv {
  border-color: #005ea8;
}
.form-input_FormInput__NLySr.form-input_is-Error__tXiB9 {
  border-color: #c82d2d;
}
.form-input_FormInput__NLySr.form-input_is-Disabled__-pB4C {
  border-color: #dcdcdc;
  background-color: #fafafa;
}
.form-input_FormInput__NLySr.form-input_has-validationBar__pLnHy {
  border-bottom: none;
}
.form-input_FormInput-label__iyZmh {
  font-size: 12px;
  font-family: Verdana;
  color: #999999;
}
.form-input_FormInput-label__iyZmh.form-input_is-Active__6CHlv {
  color: #333333;
}
.form-input_FormInput-label__iyZmh.form-input_is-Error__tXiB9 {
  color: #c82d2d;
}
.form-input_FormInput-input__MK000 {
  font-size: 14px;
  font-family: Verdana;
  border: none;
  padding: 0;
  outline: none;
  color: #333333;
  background-color: transparent;
}
.form-input_FormInput-input__MK000.form-input_is-Currency__ayRHg {
  margin-left: 14px;
}
.form-input_FormInput-input__MK000.form-input_is-Empty__YWMQj {
  margin-left: 0;
}
.form-input_FormInput-error__nYHSq {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0px;
}
.side-help-text_SideHelpText__4Tk46 {
  position: absolute;
  right: 0;
  top: 0;
}
.side-help-text_SideHelpText__4Tk46.side-help-text_PersonalData__Hzncn {
  margin-left: 16px;
  margin-top: 5px;
}
.side-help-text_SideHelpText__4Tk46.side-help-text_PersonalData-bestSSO__h4jiZ {
  margin-top: -12px;
}
.side-help-text_SideHelpText__4Tk46.side-help-text_Gf-PersonalData-bestSSO__9Xw7Q {
  margin-top: -8px;
}
.side-help-text_SideHelpText-text__4f9ht {
  position: absolute;
  width: 214px;
  left: 44px;
  padding-left: 20px;
}
.side-help-text_SideHelpText-text__4f9ht.side-help-text_is-center__WyLv1 {
  transform: translate(0, -40%);
}
.side-help-text_SideHelpText-text__4f9ht.side-help-text_is-top__0hc68 {
  top: 8px;
}
.side-help-text_SideHelpText-text__4f9ht.side-help-text_is-bottom__Hna9T {
  bottom: 0;
}
.side-help-text_SideHelpText-text__4f9ht.side-help-text_PersonalData__Hzncn {
  left: 60px;
  top: -20%;
}
@media (max-width: 1199px) {
  .side-help-text_SideHelpText-text__4f9ht.side-help-text_PersonalData__Hzncn {
    left: 35px;
  }
}
.side-help-text_SideHelpText-text__4f9ht.side-help-text_is-wide__IvJFQ {
  width: 289px;
}
.side-help-text_SideHelpText-arrow__gqZJU {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 33px;
  top: 8px;
  transform: rotate(-135deg);
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  background-color: #ffffff;
}
.side-help-text_SideHelpText-arrow__gqZJU.side-help-text_CustomerArea__Mb7Qv {
  background-color: #fafafa;
}
.side-help-text_SideHelpText-arrow__gqZJU.side-help-text_PersonalData__Hzncn {
  left: 60px;
  border: none;
  background-color: #fafafa;
}
@media (max-width: 1199px) {
  .side-help-text_SideHelpText-arrow__gqZJU.side-help-text_PersonalData__Hzncn {
    left: 35px;
  }
}
.side-help-text_SideHelpText-link__otsJl {
  cursor: help;
}
.side-help-text_SideHelpText-questionMark__k8dP7 {
  margin-top: 10px;
  width: 18px;
  height: 18px;
  fill: #b4b4b4;
}
.side-help-text_SideHelpText-questionMark__k8dP7:hover {
  fill: #999999;
}
.side-help-text_SideHelpText-questionMark__k8dP7.side-help-text_is-active__MbKPQ {
  fill: #005ea8;
}
.side-help-text_SideHelpText-questionMark__k8dP7.side-help-text_PersonalData__Hzncn {
  height: 20px;
  width: 20px;
}
.side-help-text_SideHelpText-headline__Hs-qJ {
  color: #333333;
  padding-bottom: 5px;
  font-size: 16px;
}
.user-recommendation_UserRecommendation__fiaZV {
  font-family: Verdana;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
}
.user-recommendation_UserRecommendation-smiley__CzMEG {
  fill: #333333;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.good-result-stiftung-warentest_GoodResultStiftungWarentest__SaxcS {
  font-family: Verdana;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
}
.good-result-stiftung-warentest_GoodResultStiftungWarentest-icon__SS8N1 {
  fill: #333333;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.tariff-panel_TariffPanel__4Ts3z {
  width: 222px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  position: relative;
  font-family: Verdana;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 24px;
  padding-top: 10px;
}
.tariff-panel_TariffPanel__4Ts3z.tariff-panel_is-resultZero__8R1y5 {
  padding-top: 25px;
}
.tariff-panel_TariffPanel__4Ts3z.tariff-panel_is-duvZero__SsmID {
  padding-top: 25px;
}
.tariff-panel_TariffPanel-label__IE6kS {
  margin-top: 10px;
  color: #999999;
}
.tariff-panel_TariffPanel-headlines__TDd53 {
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 15px;
}
.tariff-panel_TariffPanel-reinforcementElement__BJnRI {
  margin-top: 10px;
}
.tariff-panel_TariffPanel-divider__XTLgV {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 16px;
  margin-bottom: 16px;
}
.tariff-panel_TariffPanel-insuranceLogo__6JPZb {
  height: 30px;
  max-width: 60%;
  margin-bottom: 12px;
}
.tariff-panel_TariffPanel-insuranceName__M01qW {
  max-width: 60%;
}
.tariff-panel_TariffPanel-nameAndGradeWrapper__MIHM- {
  position: relative;
}
.tariff-panel_TariffPanel-tariffData__H5dvf {
  margin-top: 10px;
}
.tariff-panel_TariffPanel-gradeWrapper__ZLTvS {
  position: absolute;
  right: 0;
  bottom: 0;
}
.tariff-panel_TariffPanel-tariffName__v6Vik {
  max-width: 60%;
}
.tariff-panel_TariffPanel-bannerWrapper__sdnZE {
  height: 25px;
}
.spinner_Spinner__-V3C9:after {
  content: "";
  display: inline-block;
  height: 60px;
  width: 60px;
  border-style: solid;
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-width: 10px;
  animation: spinner_spinAround__bqqMT 800ms infinite linear;
}
@keyframes spinner_spinAround__bqqMT {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.appointment-calendar_AppointmentCalendar__EgxAi {
  min-width: 580px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  color: #333333;
  box-sizing: border-box;
}
.appointment-calendar_AppointmentCalendar-days__UyA9V {
  display: flex;
}
.appointment-calendar_AppointmentCalendar-column__NCroq {
  flex: 1;
}
.appointment-calendar_AppointmentCalendar-table__aTSbt {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-collapse: collapse;
}
.appointment-calendar_AppointmentCalendar-tableHeaderPhoto__0tFZl {
  height: 34px;
  width: 34px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
}
.appointment-calendar_AppointmentCalendar-tableHeaderPhotoWrapper__Kyk5Z {
  position: absolute;
  right: 3px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.appointment-calendar_AppointmentCalendar-tableTimeslot__cxGpG {
  background: #ffffff;
  text-align: center;
  padding: 3px 0px 3px 0px;
  margin: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.appointment-calendar_AppointmentCalendar-tableTimeslot__cxGpG:hover {
  border: 1px solid #333333;
}
.appointment-calendar_AppointmentCalendar-tableTimeslot__cxGpG.appointment-calendar_is-selected__JYflG {
  background: #005ea8;
  color: #ffffff;
}
.appointment-calendar_AppointmentCalendar-tableTimeslotDisabled__mFn79 {
  text-align: center;
  background-color: #f4f4f4;
  border: 1px solid transparent;
  padding: 3px 0px 3px 0px;
  margin: 4px;
  font-size: 12px;
  cursor: default;
}
.appointment-calendar_AppointmentCalendar-tableHeader__C5Qkt {
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  padding: 4px 0px 4px 10px;
  position: relative;
}
.appointment-calendar_AppointmentCalendar-tableHeader__C5Qkt.appointment-calendar_is-first__RumW- {
  border-left: none;
}
.appointment-calendar_AppointmentCalendar-spinner__aEjPl {
  position: absolute;
  left: 41.5%;
  top: 45.5%;
  z-index: 1;
}
.appointment-calendar_AppointmentCalendar-overlay__ScZOi {
  position: absolute;
  top: 44px;
  left: 0;
  background-color: #ffffff;
  opacity: 0.5;
  height: 88%;
  width: 100%;
}
.appointment-calendar_AppointmentCalendar-overlay__ScZOi {
  position: absolute;
  top: 44px;
  left: 0;
  background-color: #ffffff;
  opacity: 0.5;
  height: 88%;
  width: 100%;
}
.appointment-calendar_AppointmentCalendar-chevronWrapper__Qv0ax {
  width: 48px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  user-select: none;
  box-sizing: content-box;
}
.appointment-calendar_AppointmentCalendar-chevron__urC-U {
  height: 24px;
  color: #005ea8;
  cursor: pointer;
}
.appointment-calendar_AppointmentCalendar-chevron__urC-U.appointment-calendar_is-disabled__SL4d1 {
  color: #dcdcdc;
  cursor: initial;
}
.appointment-calendar_AppointmentCalendar-chevron__urC-U.appointment-calendar_is-left__GLLFP {
  transform: rotate(180deg);
}
.consultant-avatar_ConsultantAvatar__NGPTr {
  display: block;
}
.consultant-avatar_hidden__t3e6Z {
  display: none;
}
.versicherung-plus-badge_VersicherungPlusSignup-badge__-L9s8 {
  margin-inline-start: 0.5rem;
  padding: 0.3rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 11.5px;
  font-weight: bold;
  color: #063773;
  background-color: #ecf7fd;
  border-radius: 3px;
}
.linear-points-svg_svg-container__TF5Nn {
  height: 30px;
  width: 57px;
  cursor: help;
}
.linear-points-svg_fill-white__1WFiv {
  fill: #ffffff;
}
.linear-points-svg_fill-dark__40tOt {
  fill: #333333;
}
.linear-points-svg_text-container__Kl9R4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.linear-points-svg_text-value__C5v0r {
  fill: #333333;
  font-weight: bold;
  font-style: italic;
  font-size: 48px;
}
.linear-points-svg_text-points__dFdoj {
  fill: #333333;
  font-weight: bold;
  font-style: italic;
  font-size: 25px;
}
.linear-points-svg_text-large__hPorP {
  font-size: 48px;
}
.linear-points-svg_text-small__oCp47 {
  font-size: 43px;
}
.banner_Banner__X-AJi {
  font-family: Verdana;
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 14px;
  font-size: 14px;
  background-color: rgba(6, 55, 115, 0.15);
  width: fit-content;
}
.banner_Banner__X-AJi .banner_Banner-span__qS9W4 {
  font-weight: 600;
  color: #063773;
  white-space: nowrap;
}
@media (max-width: 1050px) {
  .banner_Banner__X-AJi .banner_Banner-span__qS9W4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
  }
}
.banner_Banner__X-AJi .banner_Banner-svg-container__CSZwY {
  display: flex;
}
.banner_Banner__X-AJi .banner_Banner-svg__nRGBk {
  fill: #063773;
  margin-left: 8px;
  pointer-events: auto;
  cursor: help;
  width: 12px;
}
.banner_Banner__X-AJi.banner_is-small-screen__pZZ-x {
  padding-left: 5px;
  padding-right: 5px;
}
.banner_Banner__X-AJi.banner_is-deadline__9NSac {
  background-color: rgba(240, 124, 0, 0.15);
}
.banner_Banner__X-AJi.banner_is-deadline__9NSac .banner_Banner-span__qS9W4 {
  color: #f07c00;
  white-space: nowrap;
}
.banner_Banner__X-AJi.banner_is-deadline__9NSac .banner_Banner-svg__nRGBk {
  fill: #f07c00;
}
.chips_Chips-container__SnmAZ {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}
.chips_Chips-container__SnmAZ .chips_Chips-error__WV7qB {
  font-family: Verdana;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: #c82d2d;
}
.chips_Chips__lHQOp {
  height: 30px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border: 1px solid #dcdcdc;
  color: #333333;
  background: #ffffff;
  font-family: Verdana;
  font-size: 13px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.chips_Chips__lHQOp:hover {
  background: #ffffff;
  border: 1px solid #005ea8;
}
.chips_Chips__lHQOp.chips_Chips-selected__1XAwy {
  background: #ecf7fd;
  border: 1px solid #005ea8;
}
.comparison-box_ComparisonBox__t7arC {
  border: 1px solid #dcdcdc;
  border-left: none;
  min-height: 360px;
  width: 100%;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background: #ffffff;
}
.comparison-box_ComparisonBox-body__wno6d {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comparison-box_ComparisonBox-footer__hrCiO {
  display: flex;
  justify-content: center;
  height: 100px;
  border-top: 1px solid #dcdcdc;
  padding-top: 10px;
}
.comparison-box-plus_ComparisonBoxPlus-plus__aUM2Q {
  height: 160px;
  cursor: pointer;
}
.comparison-box-plus_ComparisonBoxPlus-plus__aUM2Q .comparison-box-plus_ComparisonBoxPlus-plus-svg__s7ClZ {
  width: 62px;
  height: 62px;
  fill: #005ea8;
}
.comparison-box-plus_ComparisonBoxPlus-link__s9cHm {
  font-size: 14px;
  font-weight: 700;
  color: #005ea8;
  cursor: pointer;
  margin-bottom: 25px;
  margin-top: 25px;
  text-decoration: none;
}
.comparison-box-spacer_ComparisonBoxSpacer__uY62h {
  background-color: #ededed;
  border-left: 1px solid #dcdcdc;
}
.comparison-box-spacer_ComparisonBoxSpacer-button__g-38X {
  margin: 20px;
  width: 93%;
  height: 30px;
}
.comparison-box-spacer_ComparisonBoxSpacer-footer__XfX2m {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 16px;
  color: #333333;
  margin-right: auto;
}
@keyframes cta-button_spinAround__W5ChH {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.cta-button_CtaButton__ebTZD {
  width: 180px;
  height: 40px;
  border-radius: 3px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: 1px solid #0271c2;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  background-color: #0271c2;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.cta-button_CtaButton__ebTZD:focus,
.cta-button_CtaButton__ebTZD:active,
.cta-button_CtaButton__ebTZD:active:focus,
.cta-button_CtaButton__ebTZD:hover {
  outline: none;
}
.cta-button_CtaButton__ebTZD:hover {
  background-color: #015fa4;
}
.cta-button_CtaButton__ebTZD:disabled,
.cta-button_CtaButton__ebTZD.cta-button_is-disabled__h0wCr {
  background-color: #015fa4;
  border: 1px solid #015fa4;
}
.cta-button_CtaButton__ebTZD:disabled:active,
.cta-button_CtaButton__ebTZD.cta-button_is-disabled__h0wCr:active,
.cta-button_CtaButton__ebTZD:disabled:active:focus,
.cta-button_CtaButton__ebTZD.cta-button_is-disabled__h0wCr:active:focus,
.cta-button_CtaButton__ebTZD:disabled:hover,
.cta-button_CtaButton__ebTZD.cta-button_is-disabled__h0wCr:hover,
.cta-button_CtaButton__ebTZD:disabled:focus,
.cta-button_CtaButton__ebTZD.cta-button_is-disabled__h0wCr:focus {
  text-decoration: none;
}
.cta-button_CtaButton__ebTZD.cta-button_is-secondary__Jweno {
  height: auto;
  min-height: 30px;
  width: 230px;
  border: 1px solid #dcdcdc;
  color: #666666;
  background-color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.cta-button_CtaButton__ebTZD.cta-button_is-secondary__Jweno:active,
.cta-button_CtaButton__ebTZD.cta-button_is-secondary__Jweno:active:focus,
.cta-button_CtaButton__ebTZD.cta-button_is-secondary__Jweno:hover,
.cta-button_CtaButton__ebTZD.cta-button_is-secondary__Jweno:focus {
  background-color: #dcdcdc;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.cta-button_CtaButton__ebTZD.cta-button_is-inverted__ulr1i {
  background-color: #ffffff;
  color: #005ea8;
  border: 1px solid #005ea8;
}
.cta-button_CtaButton__ebTZD.cta-button_is-inverted__ulr1i:active,
.cta-button_CtaButton__ebTZD.cta-button_is-inverted__ulr1i:active:focus,
.cta-button_CtaButton__ebTZD.cta-button_is-inverted__ulr1i:hover,
.cta-button_CtaButton__ebTZD.cta-button_is-inverted__ulr1i:focus {
  outline: none;
  text-decoration: none;
  color: #005ea8;
  border: 1px solid #005ea8;
  background-color: #fafafa;
}
.cta-button_CtaButton__ebTZD .cta-button_CtaButton-label__oXtHt {
  position: relative;
}
.cta-button_CtaButton__ebTZD svg {
  height: 24px;
  width: 24px;
  animation: cta-button_spinAround__W5ChH 0.7s infinite linear;
}
.cta-button_CtaButton__ebTZD svg:after {
  height: 24px;
  width: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-width: 4px;
  content: "";
  border-color: #ffffff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
  top: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 50%;
  border-style: solid;
  border-radius: 50%;
}
.comparison-box-tariff_ComparisonBoxTariff-close__twsDK {
  position: absolute;
  right: 16px;
  top: 13px;
  height: 14px;
  fill: #b4b4b4;
  cursor: pointer;
}
.comparison-box-tariff_ComparisonBoxTariff-heart__fQesC {
  position: absolute;
  right: 12px;
  top: 35px;
}
.comparison-box-tariff_ComparisonBoxTariff-banner__wN8X7 {
  margin-top: 10px;
  margin-left: 8px;
}
.comparison-box-tariff_ComparisonBoxTariff-white-space__gQJAU {
  height: 15px;
}
.comparison-box-tariff_ComparisonBoxTariff-insuranceLogo__PiYET {
  height: 50px;
  margin-bottom: 25px;
  margin-top: 20px;
  max-width: 150px;
}
.comparison-box-tariff_ComparisonBoxTariff-tariff-name__EOcet {
  text-align: center;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 5px;
  height: 20px;
}
.comparison-box-tariff_ComparisonBoxTariff-price-container__Ram6E {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}
.comparison-box-tariff_ComparisonBoxTariff-price-container__Ram6E :first-child {
  color: #333333;
  font-size: 20px;
  margin-top: 50px;
}
.comparison-box-tariff_ComparisonBoxTariff-price-container__Ram6E :last-child {
  color: #333333;
  font-size: 12px;
  line-height: 1.5;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
}
.comparison-box-tariff_ComparisonBoxTariff-price-container__Ram6E.comparison-box-tariff_has-backdated-prices__CXY20 {
  margin-bottom: 39px;
}
.comparison-box-tariff_ComparisonBoxTariff-cta-button__BKWQM {
  margin-bottom: 16px;
}
.wishlist-heart_WishlistHeart-Container__adx-K svg {
  width: 22px;
  height: 20px;
  fill: #333333;
  cursor: pointer;
}
.wishlist-heart_WishlistHeart-Container__adx-K svg.wishlist-heart_WishlistHeart-saved__8MTw7 {
  fill: #c82d2d;
}
.tariff-backdate-price_TariffBackdatePrice__vfvyA {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tariff-backdate-price_TariffBackdatePrice-old-price__QeyFX {
  display: flex;
  gap: 5px;
  margin-top: 35px;
  align-items: center;
}
.tariff-backdate-price_TariffBackdatePrice-old-price__QeyFX .tariff-backdate-price_TariffBackdatePrice-old-price-span__-isIO {
  font-size: 13px;
  color: #c82d2d;
  text-decoration: line-through;
}
.tariff-backdate-price_TariffBackdatePrice-old-price__QeyFX .tariff-backdate-price_TariffBackdatePrice-old-price-svg__v-zyr {
  min-height: 12px;
  fill: #c82d2d;
  cursor: help;
}
.tariff-backdate-price_TariffBackdatePrice-price-container__ycYTn {
  margin-bottom: 0px;
}
.tariff-backdate-price_TariffBackdatePrice-price-container__ycYTn :first-child {
  margin-top: 0px;
}
.tariff-backdate-price_TariffBackdatePrice-you-save__5jRjv {
  color: #333333;
  font-size: 13px;
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  margin-top: 3px;
}
.tariff-backdate-price_TariffBackdatePrice-you-save__5jRjv .tariff-backdate-price_TariffBackdatePrice-you-save-price__sUWlg {
  color: #7ab51d;
}
.tariff-backdate-price_TariffBackdatePrice-you-save__5jRjv .tariff-backdate-price_TariffBackdatePrice-you-save-svg__1atUv {
  height: 12px;
  fill: #7ab51d;
  cursor: help;
  margin-top: 2px;
}
.consultant-carousel-item_c24-bu-link__GkNvf:hover {
  color: #c05702;
  text-decoration: underline;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneNumber__Fdikr {
  font-size: 14px;
  color: #005ea8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneNumber__Fdikr:hover {
  color: #c05702;
  text-decoration: underline;
}
.consultant-carousel-item_ConsultantCarouselItem-container__Lt0Ce {
  background-color: #ffffff;
  padding: 10px 15px;
}
.consultant-carousel-item_ConsultantCarousel-headline__dP3Rl {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.consultant-carousel-item_ConsultantCarouselItem-consultantDetails__17ZyN {
  margin-top: 10px;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneSvg__NsoTj {
  fill: #005ea8;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-additionalText__xz9UK {
  display: flex;
  align-items: flex-end;
  color: #005ea8;
  fill: #005ea8;
  font-size: 12px;
  width: 75px;
  justify-content: flex-end;
}
.consultant-carousel-item_ConsultantCarouselItem-additionalText__xz9UK:hover {
  color: #c05702;
  text-decoration: underline;
}
.consultant-carousel-item_ConsultantCarouselItem-expandCollapseArrow__68yrV {
  width: 13px;
  height: 7px;
  margin-left: 5px;
  bottom: 6px;
  position: relative;
}
.consultant-carousel-item_ConsultantCarouselItem-consultantDetails__17ZyN {
  display: flex;
  justify-content: space-between;
}
.consultant-carousel-item_ConsultantCarouselItem-infoQuote__6CoQ8 {
  font-size: 12px;
  color: #999999;
}
.consultant-carousel-item_ConsultantCarouselItem-infoTitle__ZyWRO {
  font-size: 14px;
  color: #333333;
}
.consultant-carousel-item_ConsultantCarouselItem-name__Gb0nT {
  text-align: center;
  font-size: 16px;
  color: #333333;
  margin-top: 6px;
}
.consultant-carousel-item_ConsultantCarouselItem-photoAndQuote__mI6oS {
  display: flex;
  padding: 0 30px;
}
.consultant-carousel-item_ConsultantCarouselItem-photoContainer__b4WKe {
  margin-right: 30px;
  min-width: 100px;
}
.consultant-carousel-item_ConsultantCarouselItem-languages__8IAYp {
  width: 250px;
  margin-right: -20px;
}
.consultant-carousel-item_ConsultantCarouselItem-quote__CIyLY {
  margin-top: 10px;
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  background-color: #f4f4f4;
  color: #333333;
  position: relative;
  height: 100%;
}
.consultant-carousel-item_ConsultantCarouselItem-arrow__HGzCV {
  display: block;
  position: absolute;
  top: 50%;
  left: -16px;
  width: 11px;
  height: 0;
}
.consultant-carousel-item_ConsultantCarouselItem-arrow__HGzCV:after {
  content: " ";
  border-color: transparent #f4f4f4;
  border-image: none;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  width: 1px;
  position: absolute;
  margin-top: -12px;
  top: 50%;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-container__p25Tt {
  background-color: #ffffff;
  padding: 30px 15px 25px 15px;
  border-top: 1px solid #dcdcdc;
  position: relative;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-closeButton__FGo-D {
  position: absolute;
  top: 10px;
  height: 10px;
  right: 15px;
  width: 12px;
  fill: #b4b4b4;
  cursor: pointer;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-header__A5uR9 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 6px;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-headlineRow__cEEi7 {
  display: flex;
  margin-top: 25px;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-headlineIcon__OZM3J {
  width: 80px;
  text-align: center;
  margin-right: 10px;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-headlineHeader__c2VxD {
  font-size: 15px;
  color: #333333;
  font-weight: bold;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-headlineInfoText__fBwgp {
  font-size: 13px;
  line-height: 20px;
  color: #333333;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-icon__niPJQ {
  width: 60px;
  height: 60px;
  position: relative;
  top: 3px;
  fill: #333333;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-icon__niPJQ.consultant-carousel-additional-text_is-cobrowsing__R1CzO {
  width: 75px;
  height: 55px;
}
.consultant-carousel-additional-text_ConsultantCarouselAdditionalText-headlineInfo__LIf9U {
  flex: 1;
}
/* Slider */
.consultant-carousel_slick-slider__BXDGg {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.consultant-carousel_slick-list__k0oNS {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.consultant-carousel_slick-list__k0oNS:focus {
  outline: none;
}
.consultant-carousel_slick-list__k0oNS.consultant-carousel_dragging__-4J1r {
  cursor: pointer;
  cursor: hand;
}
.consultant-carousel_slick-slider__BXDGg .consultant-carousel_slick-track__W17On,
.consultant-carousel_slick-slider__BXDGg .consultant-carousel_slick-list__k0oNS {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.consultant-carousel_slick-track__W17On {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.consultant-carousel_slick-track__W17On:before,
.consultant-carousel_slick-track__W17On:after {
  content: "";
  display: table;
}
.consultant-carousel_slick-track__W17On:after {
  clear: both;
}
.consultant-carousel_slick-loading__zhTfG .consultant-carousel_slick-track__W17On {
  visibility: hidden;
}
.consultant-carousel_slick-slide__otftF {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .consultant-carousel_slick-slide__otftF {
  float: right;
}
.consultant-carousel_slick-slide__otftF img {
  display: block;
}
.consultant-carousel_slick-slide__otftF.consultant-carousel_slick-loading__zhTfG img {
  display: none;
}
.consultant-carousel_slick-slide__otftF.consultant-carousel_dragging__-4J1r img {
  pointer-events: none;
}
.consultant-carousel_slick-initialized__wzRQz .consultant-carousel_slick-slide__otftF {
  display: block;
}
.consultant-carousel_slick-loading__zhTfG .consultant-carousel_slick-slide__otftF {
  visibility: hidden;
}
.consultant-carousel_slick-vertical__WEyuH .consultant-carousel_slick-slide__otftF {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.consultant-carousel_slick-arrow__zsAPd.consultant-carousel_slick-hidden__wsPLp {
  display: none;
}
/* Slider */
.consultant-carousel_slick-loading__zhTfG .consultant-carousel_slick-list__k0oNS {
  background: #fff url('slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.consultant-carousel_slick-prev__G2rTv,
.consultant-carousel_slick-next__c1pBM {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.consultant-carousel_slick-prev__G2rTv:hover,
.consultant-carousel_slick-next__c1pBM:hover,
.consultant-carousel_slick-prev__G2rTv:focus,
.consultant-carousel_slick-next__c1pBM:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.consultant-carousel_slick-prev__G2rTv:hover:before,
.consultant-carousel_slick-next__c1pBM:hover:before,
.consultant-carousel_slick-prev__G2rTv:focus:before,
.consultant-carousel_slick-next__c1pBM:focus:before {
  opacity: 1;
}
.consultant-carousel_slick-prev__G2rTv.consultant-carousel_slick-disabled__loGEr:before,
.consultant-carousel_slick-next__c1pBM.consultant-carousel_slick-disabled__loGEr:before {
  opacity: 0.25;
}
.consultant-carousel_slick-prev__G2rTv:before,
.consultant-carousel_slick-next__c1pBM:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('slick-carousel/slick/fonts/slick.eot');
  src: url('slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('slick-carousel/slick/fonts/slick.woff') format('woff'), url('slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
.consultant-carousel_slick-prev__G2rTv {
  left: -25px;
}
[dir="rtl"] .consultant-carousel_slick-prev__G2rTv {
  left: auto;
  right: -25px;
}
.consultant-carousel_slick-prev__G2rTv:before {
  content: "←";
}
[dir="rtl"] .consultant-carousel_slick-prev__G2rTv:before {
  content: "→";
}
.consultant-carousel_slick-next__c1pBM {
  right: -25px;
}
[dir="rtl"] .consultant-carousel_slick-next__c1pBM {
  left: -25px;
  right: auto;
}
.consultant-carousel_slick-next__c1pBM:before {
  content: "→";
}
[dir="rtl"] .consultant-carousel_slick-next__c1pBM:before {
  content: "←";
}
/* Dots */
.consultant-carousel_slick-dotted__aXVk9 .consultant-carousel_slick-slider__BXDGg {
  margin-bottom: 30px;
}
.consultant-carousel_slick-dots__37y2Z {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.consultant-carousel_slick-dots__37y2Z li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.consultant-carousel_slick-dots__37y2Z li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.consultant-carousel_slick-dots__37y2Z li button:hover,
.consultant-carousel_slick-dots__37y2Z li button:focus {
  outline: none;
}
.consultant-carousel_slick-dots__37y2Z li button:hover:before,
.consultant-carousel_slick-dots__37y2Z li button:focus:before {
  opacity: 1;
}
.consultant-carousel_slick-dots__37y2Z li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.consultant-carousel_slick-dots__37y2Z li.consultant-carousel_slick-active__xKMgS button:before {
  color: black;
  opacity: 0.75;
}
.consultant-carousel_ConsultantCarousel-wrapper__z4iU3 {
  min-width: 750px;
  margin-top: 10px;
  border: 1px solid #dcdcdc;
  max-width: 1005px;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  box-sizing: border-box;
}
.consultant-carousel_ConsultantCarousel-wrapper__z4iU3.consultant-carousel_is-wide__vgm1b {
  max-width: none;
}
.consultant-carousel_slick-prev__G2rTv,
.consultant-carousel_slick-next__c1pBM {
  z-index: 1;
  fill: #dcdcdc;
  height: 36px;
  width: 10px;
  right: 15px;
}
.consultant-carousel_slick-prev__G2rTv:before,
.consultant-carousel_slick-next__c1pBM:before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -30px;
}
.consultant-carousel_slick-prev__G2rTv.consultant-carousel_is-mini-carousel__LCvPD,
.consultant-carousel_slick-next__c1pBM.consultant-carousel_is-mini-carousel__LCvPD {
  top: 25%;
}
.consultant-carousel_slick-prev__G2rTv.consultant-carousel_is-mini-long-carousel__e-m-K,
.consultant-carousel_slick-next__c1pBM.consultant-carousel_is-mini-long-carousel__e-m-K {
  top: 20%;
}
.consultant-carousel_slick-prev__G2rTv.consultant-carousel_is-mini-carousel__LCvPD,
.consultant-carousel_slick-next__c1pBM.consultant-carousel_is-mini-carousel__LCvPD {
  right: 20px;
}
.consultant-carousel_slick-prev__G2rTv {
  left: 15px;
}
.consultant-carousel_slick-prev__G2rTv.consultant-carousel_is-mini-carousel__LCvPD {
  left: 20px;
}
.consultant-carousel_nextArrow__WWReh,
.consultant-carousel_prevArrow__lzMye {
  height: 36px;
  width: 10px;
}
.consultant-carousel_ConsultantCarousel-group-enter__ffPLi {
  opacity: 0.01;
}
.consultant-carousel_ConsultantCarousel-group-enter-active__jzZnC {
  opacity: 0.01;
}
.consultant-carousel_ConsultantCarousel-group-enter-done__l5PcE {
  opacity: 1;
  transition: opacity 300ms linear;
}
.consultant-carousel_ConsultantCarousel-group-exit__41lP3 {
  opacity: 1;
}
.consultant-carousel_ConsultantCarousel-group-exit-active__76Fxy {
  opacity: 0.01;
  transition: opacity 100ms linear;
}
.consultant-carousel-item-mini_c24-bu-link__XigD2:hover {
  color: #c05702;
  text-decoration: underline;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-phoneNumber__leLf7 {
  font-size: 14px;
  color: #005ea8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-phoneNumber__leLf7:hover {
  color: #c05702;
  text-decoration: underline;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-container__bk9Qv {
  background-color: #ffffff;
  padding: 10px 10px;
  height: 250px;
  box-sizing: border-box;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-container__bk9Qv.consultant-carousel-item-mini_is-long__PCeWV {
  height: 313px;
}
.consultant-carousel-item-mini_ConsultantCarousel-headline__wWLtK {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-phoneSvg__R-xSU {
  fill: #005ea8;
  width: 25px;
  height: 25px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-consultantDetails__jylis {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-infoQuote__ziqed {
  font-size: 12px;
  color: #999999;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-infoTitleContainer__9RDdK {
  text-align: center;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-infoTitle__suJU5 {
  font-size: 13px;
  color: #333333;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-name__U1rAL {
  text-align: center;
  font-size: 16px;
  color: #333333;
  margin-top: 15px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-name__U1rAL.consultant-carousel-item-mini_is-long__PCeWV {
  margin-top: 20px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-photoAndQuote__TQxv0 {
  display: flex;
  padding: 0 30px;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-photoContainer__lK4Q4 {
  min-width: 100px;
  margin: auto;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-phoneNumberContainer__io8jj {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.consultant-carousel-item-mini_ConsultantCarouselItemMini-phoneNumberContainer__io8jj.consultant-carousel-item-mini_is-long__PCeWV {
  margin-top: 35px;
}
.consultant-carousel-mini_ConsultantCarouselMini-wrapper__mniFB {
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  width: 200px;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
}
.consultant-carousel-mini_ConsultantCarouselMini-wrapper__mniFB > .consultant-carousel-mini_slick-slider__Ic9pm {
  height: 250px;
}
.consultant-carousel-mini_ConsultantCarouselMini-wrapper__mniFB.consultant-carousel-mini_is-long__JGT9F > .consultant-carousel-mini_slick-slider__Ic9pm {
  height: 313px;
}
.points-gratification-bar_PointsInfoContent__B6vhr {
  display: flex;
  align-items: center;
  margin-top: -13px;
  margin-bottom: 20px;
  height: 28px;
  width: 100%;
  font-family: Verdana;
  font-size: 11px;
  font-weight: bold;
  padding-left: 50px;
  background-color: #c1e3e3;
  color: #005c61;
}
.points-gratification-bar_PointsInfoContent-PointsIcon__epB-v {
  flex-shrink: 0;
  width: 17px;
  height: 12px;
  fill: #005c61;
  margin-top: 2px;
  margin-right: 10px;
  margin-left: 50px;
}
@media (max-width: 1280px) {
  .points-gratification-bar_PointsInfoContent-PointsIcon__epB-v {
    padding-left: 15px;
  }
}
.points-gratification-bar_PointsInfoContent-QuestionIcon__bFugl {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: #005c61;
  margin-left: 10px;
}
.points-gratification-bar_PointsInfoTooltip__aFBNS {
  padding: 0px !important;
  pointer-events: auto !important;
  z-index: 1000 !important;
}
.points-gratification-bar_PointsInfoTooltip-Content__9XRKv {
  display: flex;
  align-items: flex-start;
}
.points-gratification-bar_PointsInfoTooltip-Text__lIu4x {
  margin: 15px 0 15px 15px;
  flex-basis: 100%;
  line-height: 1.3;
}
.points-gratification-bar_PointsInfoTooltip-CloseIcon__HINMx {
  margin-top: 5px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  fill: #dcdcdc;
  cursor: pointer;
}
.points-gratification-bar_PointsInfoTooltip-PointsContent__sLKIw {
  display: flex;
  flex-direction: column;
}
.radio-button_RadioButton__vKsaB {
  border: 1px solid #b4b4b4;
  appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: relative;
}
.radio-button_RadioButton__vKsaB:hover {
  border: 1px solid #005ea8;
  cursor: pointer;
}
.radio-button_RadioButton__vKsaB:checked {
  border: 1px solid #005ea8;
}
.radio-button_RadioButton__vKsaB:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #005ea8;
  transform: translate(-50%, -50%);
}
.radio-button_RadioButton__vKsaB:disabled {
  background: #cdcdcd;
}
.radio-button_RadioButton__vKsaB:disabled::before {
  background-color: #999999;
}
.radio-button_RadioButton__vKsaB:disabled:hover {
  border: 1px solid #b4b4b4;
}
.radio-button_RadioButton__vKsaB:disabled:hover:checked {
  border: 1px solid #999999;
}
.radio-button_RadioButton__vKsaB:disabled:checked {
  border: 1px solid #999999;
}
.result-zero-banner_ResultZeroBanner--moveLeft__f3OJ3 {
  margin-left: -16px;
}
.savings-campaign-banner_SavingsCampaignBanner__AQUxi {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -4px;
  height: 90px;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__XXuZB {
  height: 90px;
  z-index: 0;
  margin-left: -45px;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__XXuZB svg {
  width: 90px;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__XXuZB svg circle {
  fill: #fafafa;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__XXuZB svg path {
  fill: #063773;
}
.savings-campaign-banner_SavingsCampaignBanner-text__xZ85Q {
  color: #063773;
  font-family: Verdana;
  font-size: 10px;
  line-height: 9px;
  font-weight: 900;
}
.savings-campaign-banner_SavingsCampaignBanner-number__-Vd5Q {
  color: #febb00;
  font-family: Verdana;
  font-size: 30px;
  line-height: 30px;
  font-weight: 900;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown-text__Z5lgB {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -80px;
  padding-right: 4px;
}
.savings-campaign-banner_SavingsCampaignBanner-info__c0T5u {
  background: #febb00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-left: 45px;
}
.savings-campaign-banner_SavingsCampaignBanner-info__c0T5u .savings-campaign-banner_SavingsCampaignBanner-info-text__ZRqYi {
  display: flex;
  font-family: Verdana;
  font-size: 16px;
  color: #063773;
}
.savings-campaign-banner_SavingsCampaignBanner-info__c0T5u .savings-campaign-banner_SavingsCampaignBanner-info-text__ZRqYi span {
  font-weight: 600;
}
.savings-campaign-banner_SavingsCampaignBanner-info__c0T5u .savings-campaign-banner_SavingsCampaignBanner-info-text__ZRqYi svg {
  fill: #063773 !important;
  width: 16px;
  margin-left: 10px;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__vBA7r {
  margin-right: 24px;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__vBA7r svg {
  width: 37px;
  height: 33px;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__vBA7r svg path {
  fill: #063773;
}
.savings-campaign-banner_SavingsCampaignBanner-white__WGfkH svg circle {
  fill: #ffffff;
}
.savings-campaign-banner_SavingsCampaignBanner-tooltipHeadline__Km8Gf {
  color: #333333;
  font-weight: bold;
}
.savings-campaign-banner_SavingsCampaignBanner-tooltip-container__9jbsm {
  height: 16px;
  margin-top: 2px;
}
.theo_Theo__OLPxG {
  margin-left: 48px;
  margin-top: 15px;
}
.theo_Theo-container__xC2L2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}
.theo_Theo-container__xC2L2 svg {
  width: 100px;
  height: 100px;
}
.theo_Theo-container__xC2L2 span {
  margin-left: 24px;
  font-family: Verdana;
  font-size: 16px;
  color: #333333;
  line-height: 18px;
}
.theo_Theo-question__dwxSY {
  font-family: Verdana;
  font-size: 22px;
  color: #333333;
}
.trust-footer_TrustFooter__DrpwV {
  width: 850px;
  background-color: #cce7a0;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  box-sizing: border-box;
}
.trust-footer_TrustFooter-is-wide__dwF8z {
  width: 100%;
}
.trust-footer_TrustFooter-title__6yl-v {
  font-weight: 700;
  width: 810px;
  height: 19px;
  font-family: Verdana;
  font-size: 16px;
  color: #333333;
  margin-bottom: 15px;
}
.trust-footer_TrustFooter-content__nJFpy {
  display: flex;
  align-items: flex-start;
  gap: 35px;
}
.trust-footer_TrustFooter-item__DpUet {
  display: flex;
  align-items: flex-start;
}
.trust-footer_TrustFooter-item__DpUet span {
  font-family: Verdana;
  font-size: 14px;
  color: #333333;
  margin-left: 10px;
}
.trust-footer_TrustFooter-item__DpUet svg {
  fill: #005ea8;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.duv-banner_DuvBannerHeader__UEKs8 {
  margin-left: -16px;
}
.price-panel_PricePanel__yfEEC {
  width: 270px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  position: relative;
  font-family: Verdana;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 24px;
  box-sizing: border-box;
}
.price-panel_PricePanel__yfEEC.price-panel_has-campaign__5mU2B {
  padding-top: 10px;
}
.price-panel_PricePanel__yfEEC .price-panel_PricePanel-banner__p4wTS {
  margin-left: -16px;
  margin-bottom: 14px;
}
.price-panel_PricePanel-header__v6oGS {
  font-family: Verdana;
  font-size: 16px;
  color: #333333;
}
.price-panel_PricePanel-redInfoIcon__cDanm {
  fill: #c82d2d;
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.price-panel_PricePanel-regularPriceWrapper__Feokw {
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.price-panel_PricePanel-columnWrapper__vBYKS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #999999;
  margin-top: 8px;
}
.price-panel_PricePanel-regularPrice__SJ7XD {
  text-decoration: line-through;
  color: #c82d2d;
}
.price-panel_PricePanel-price__VmdoV {
  color: #333333;
  font-size: 20px;
}
.price-panel_PricePanel-separator__dltI9 {
  border-bottom: 1px solid #dcdcdc;
  margin: 16px 0;
}
.price-panel_PricePanel-points__O0CFm {
  color: #333333;
}
.price-panel_PricePanel-savingsWrapper__wtUpu {
  display: flex;
  align-items: center;
  color: #7ab51d;
}
.price-panel_PricePanel-greenInfoIcon__P0jds {
  fill: #7ab51d;
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.price-panel_PricePanel-campaignText__PYdxe {
  font-weight: 500;
}
.roland-promotion-panel_RolandPromotionPanel__-BZEs {
  width: 270px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  position: relative;
  font-family: Verdana;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 24px;
  padding-top: 10px;
}
.roland-promotion-panel_RolandPromotionPanel-labelGrey__uLOp- {
  margin-top: 10px;
  color: #333333;
}
.roland-promotion-panel_RolandPromotionPanel-headlines__GprTd {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.roland-promotion-panel_RolandPromotionPanel-divider__3qkMP {
  width: 100%;
  height: 0;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 16px;
  margin-bottom: 16px;
}
.roland-promotion-panel_RolandPromotionPanel-logo__60Ion {
  max-height: 34px;
  max-width: 60%;
  margin-bottom: 5px;
}
.roland-promotion-panel_RolandPromotionBanner__lgfbV {
  margin-left: -16px;
}
.roland-promotion-panel_RolandPromotionPanel-helpText__SN4Cz {
  font-weight: 500;
}
.roland-promotion-panel_RolandPromotionPanel-helpTextTitle__pHDtc {
  font-weight: 700;
  color: #063773;
  margin-bottom: 15px;
}
.roland-promotion-panel_RolandPromotionPanel-helpTextTitle__pHDtc p {
  font-weight: 500;
}
.roland-promotion-panel_RolandPromotionPanel-body__-e5wg {
  width: 577px!important;
}
.mobile_Mobile-container__8M2Zc {
  background: rgba(51, 51, 51, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow: hidden;
}
.mobile_Mobile__5bafv {
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 5px 15px rgba(51, 51, 51, 0.5);
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  background-color: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.2);
  background-clip: padding-box;
  outline: 0;
  max-height: calc(80vh - 80px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  z-index: 1051;
  box-sizing: border-box;
}
.mobile_Mobile__5bafv.mobile_is-centered__jTFqx {
  transform: translate(-50%, -50%);
  top: 50%;
  width: 95%;
}
.mobile_Mobile-body__Z19eD {
  font-size: 16px;
  position: relative;
  color: #333333;
  overflow: auto;
}
.mobile_Mobile-body__Z19eD > :first-child {
  margin: 15px;
}
.mobile_Mobile-is-open__Uwczd {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
  height: 100vh;
}
.header_Header-title__I-s7K {
  color: #333333;
  font-size: 16px;
  margin: 15px;
  margin-bottom: 0px;
}
.header_Header-title__I-s7K.header_is-desktop__lr1nt {
  margin: 0px;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}
.header_Header-close__TACvK {
  position: absolute;
  width: 20px;
  height: 20px;
  fill: #ffffff;
  right: 0px;
  top: -30px;
  pointer-events: auto;
}
.desktop_Desktop-container__MyEeH {
  background: rgba(51, 51, 51, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow: hidden;
}
.desktop_Desktop__8Wjes {
  position: fixed;
  left: 30%;
  top: 30%;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  z-index: 1051;
  box-sizing: border-box;
  padding: 15px;
  width: 520px;
}
.desktop_Desktop-body__27KTX {
  font-size: 16px;
  position: relative;
  color: #333333;
  overflow: auto;
}
.footer_Footer-container__ChyWA {
  height: 58px;
  justify-content: space-around;
  display: flex;
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
}
.footer_Footer-container__ChyWA > * {
  display: block;
  width: 50%;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_Footer-container__ChyWA > :not(:only-child):first-child {
  border-right: 1px solid #dcdcdc;
}
.footer_Footer-container__ChyWA > :not(:only-child):last-child {
  font-weight: 700;
}
.container_Container__lnxfR {
  width: 100%;
  font-family: Verdana;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-size: 12px;
  background-color: #ffffff;
}
.header_Header__6DTbU {
  font-size: 14px;
  margin: 24px 0 10px 0;
}
.separator_Separator__wFL35 {
  height: 1px;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  margin-top: 24px;
}
.separator_Separator__wFL35.separator_isWide__fQbtj {
  margin: 0 -24px;
  width: calc(100% + 48px);
}
.question-mark_QuestionMarkSvg__pa21u {
  fill: #b4b4b4;
}
.question-mark_QuestionMarkContainer__UuhLZ {
  width: 16px;
  height: 16px;
  cursor: help;
}
.tab_TabMenu__itnlB {
  display: flex;
  font-size: 14px;
  color: #666666;
  gap: 32px;
}
.tab_TabMenuItem__5AOoe {
  padding-bottom: 10px;
  cursor: pointer;
}
.tab_TabMenuItem__5AOoe.tab_active__C2KwW {
  color: #333333;
  font-weight: bold;
  border-bottom: 2px solid #333333;
  margin-bottom: -1px;
}
.tab_TabMenuItem__5AOoe.tab_top-right-corner-element__yozeT {
  margin-left: auto;
}
.usp-value_UspValueGreen__m6tlZ {
  fill: #7ab51d;
  width: 16px;
  height: 16px;
}
.usp-value_UspValueRed__vQUR7 {
  fill: #e30613;
  width: 16px;
  height: 16px;
}
.usp-value_UspValueOrange__FCpu6 {
  fill: #febb00;
  width: 16px;
  height: 16px;
}
.important-features-grid_ImportantFeaturesGrid__UWDFj {
  display: grid;
  grid-template-columns: 16px 140px auto 16px;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 15px;
}
.all-features-grid_AllFeaturesGrid__K9yAs {
  display: grid;
  grid-template-columns: 140px 16px 80px auto;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 15px;
}
.documen-link_DocumentLink__6UOrj {
  display: flex;
  align-items: center;
  gap: 7px;
}
.documen-link_DocumentLinkIcon__DUdBH {
  height: 20px;
  width: 20px;
  fill: #005ea8;
}
.documen-link_DocumentLinkAnchor__v7uoP {
  color: #005ea8;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}
.documen-link_DocumentLinkAnchor__v7uoP:hover {
  text-decoration: underline;
  color: #c05702;
}
.documents-grid_DocumentsGrid__xQcCr {
  display: grid;
  grid-template-columns: 140px 16px auto;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 15px;
}
.documents-grid_DocumentsField__-9xNk {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.validation-bar_ValidationBar-bar__lW-yL {
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: 5px;
  border-bottom-left-radius: 140px;
  border-bottom-right-radius: 140px;
  border-bottom: 2px solid;
  z-index: 1;
  transition-property: width;
  transition-duration: 0.25s;
}
.validation-bar_ValidationBar-bar__lW-yL.validation-bar_is-error__tAi-3 {
  border-color: #c82d2d;
  width: calc(100% + 2px);
}
.validation-bar_ValidationBar-bar__lW-yL.validation-bar_is-valid__u3Cv5 {
  border-color: #7ab51d;
  width: calc(100% + 2px);
}
.form-input_FormInput-Wrapper__TfUqI {
  font-family: Arial, Helvetica, Sans-Serif;
  position: relative;
  width: 100%;
  height: 58px;
  font-size: 18px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-sizing: border-box;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-currency__PnBbi:before {
  position: absolute;
  top: 26px;
  content: "€";
  left: 17px;
  font-size: 18px;
  font-family: Arial, Helvetica, Sans-Serif;
  color: #333333;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-empty__RoxZz:before {
  content: "";
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-active__mb4m6 {
  border-color: #005ea8;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-error__KHqlp {
  border-color: #c82d2d;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-disabled__-mrDL {
  background-color: #f4f4f4;
  color: #666666;
  border-color: #dcdcdc;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-disabled__-mrDL:before {
  color: #666666;
  opacity: 1;
}
.form-input_FormInput-Wrapper__TfUqI.form-input_is-disabled__-mrDL input {
  color: #666666;
  -webkit-text-fill-color: #666666;
  opacity: 1;
}
.form-input_FormInput-reset-ctaButton__anUcp {
  margin-bottom: 10px;
}
.form-input_FormInput-field__Nr-jd {
  width: 100%;
  font-family: Arial, Helvetica, Sans-Serif;
  color: #333333;
  border: none;
  font-size: 18px;
  font-weight: normal;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  padding: 0 16px 0 16px;
  height: 22px;
  padding-top: 3px;
  margin-top: 3px;
}
.form-input_FormInput-field__Nr-jd.form-input_is-disabled__-mrDL {
  background-color: #f4f4f4;
  color: #666666;
}
.form-input_FormInput-field__Nr-jd.form-input_is-active__mb4m6 {
  padding-right: 40px;
}
.form-input_FormInput-field__Nr-jd.form-input_is-currency__PnBbi {
  padding-left: 30px;
}
.form-input_FormInput-field__Nr-jd.form-input_is-empty__RoxZz {
  padding-left: 16px;
}
.form-input_FormInput-field__Nr-jd.form-input_has-no-label__U9-Bw {
  margin-top: 18px;
  margin-bottom: 18px;
}
.form-input_FormInput-field__Nr-jd::placeholder {
  color: #999999;
}
.form-input_FormInput-error__jh83T {
  font-family: Arial, Helvetica, Sans-Serif;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 14px;
}
.form-input_FormInput-label__zQylS {
  color: #666666;
  font-size: 13px;
  padding-left: 16px;
  padding-top: 8px;
  margin-bottom: 0px;
  min-height: 16px;
  line-height: normal;
}
.form-input_FormInput-label__zQylS.form-input_is-active__mb4m6 {
  color: #005ea8;
}
.form-input_FormInput-label__zQylS.form-input_is-error__KHqlp {
  color: #c82d2d;
}
.form-input_FormInput-label__zQylS.form-input_is-disabled__-mrDL {
  color: #999999;
}
.form-input_FormInput-clearSvg__lYTTO {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  fill: #999999;
  position: absolute;
  right: 6px;
  top: 0px;
}
.form-input_FormInput-clearSvg__lYTTO.form-input_has-modal__yUiZP {
  right: 56px;
}
.form-input_FormInput-clearSvg-internal__3HWng {
  width: 20px;
  height: 20px;
}
.form-input_FormInput-helpText__W89zO {
  font-family: Arial, Helvetica, Sans-Serif;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin-top: 4px;
  color: #666666;
  font-size: 14px;
}
.form-input_FormInput-questionMark__zghjo {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 15px;
  fill: #999999;
}
.suggestion-list_SuggestionContainer__tFTDq {
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.16);
  max-height: 250px;
  left: 0;
  right: 0;
  top: 21px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #b4b4b4;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 1;
}
.suggestion-list_SuggestionContainer__tFTDq ul li:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}
.suggestion-list_SuggestionContainer__tFTDq ul li:first-child {
  background-color: #efeff4;
}
.suggestion-list_SuggestionContainer__tFTDq::-webkit-scrollbar {
  display: none;
}
.suggestion_Suggestion__KnavV {
  font-size: 18px;
  font-family: Arial, Helvetica, Sans-Serif;
  color: #333333;
  font-weight: normal;
}
.suggestion_Suggestion-match__QFZd3 {
  font-size: 18px;
  font-family: Arial, Helvetica, Sans-Serif;
  color: #333333;
  font-weight: bold;
}
.input-auto-suggest_InputAutoSuggest-input__-3951 {
  width: 100%;
  font-family: Arial, Helvetica, Sans-Serif;
  color: #333333;
  border: none;
  font-size: 18px;
  font-weight: normal;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  padding: 0 16px 0 16px;
  height: 22px;
  padding-top: 3px;
  background-color: transparent;
}
.input-auto-suggest_InputAutoSuggest-input__-3951.input-auto-suggest_is-active__6u9zp {
  padding-right: 40px;
}
.input-auto-suggest_InputAutoSuggest-input__-3951.input-auto-suggest_is-currency__K5gBV {
  padding-left: 23px;
}
.input-auto-suggest_InputAutoSuggest-input__-3951::placeholder {
  color: #999999;
}
.input-auto-suggest_InputAutoSuggest-input__-3951.input-auto-suggest_has-helptext__-kcBE {
  padding-right: 40px;
}
.input-auto-suggest_InputAutoSuggest-input__-3951.input-auto-suggest_is-active__6u9zp.input-auto-suggest_InputAutoSuggest-input__-3951.input-auto-suggest_has-helptext__-kcBE {
  padding-right: 70px;
}
.input-auto-suggest_InputAutoSuggest-clear__PVwtZ {
  width: 20px;
  height: 20px;
  fill: #999999;
  position: absolute;
  right: 16px;
  top: 18px;
}
.input-auto-suggest_InputAutoSuggest-clear__PVwtZ.input-auto-suggest_has-modal__CMevH {
  right: 56px;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__hTjWL {
  height: 52px;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__hTjWL li {
  list-style-type: none;
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  line-height: 1.3;
}
.input-auto-suggest_InputAutoSuggest-suggestionContainer__hTjWL ul {
  padding: 0;
  margin: 0;
}
.input-auto-suggest_InputAutoSuggest-label__f8llY {
  color: #666666;
  font-size: 13px;
  padding-left: 16px;
  padding-top: 8px;
  margin-bottom: 0px;
}
.input-auto-suggest_InputAutoSuggest-label__f8llY.input-auto-suggest_is-active__6u9zp {
  color: #005ea8;
}
.input-auto-suggest_InputAutoSuggest-label__f8llY.input-auto-suggest_is-error__RpDQw {
  color: #c82d2d;
}
.input-auto-suggest_InputAutoSuggest-inputBorder__rtX9U {
  height: 58px;
  font-size: 18px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  position: relative;
}
.input-auto-suggest_InputAutoSuggest-inputBorder__rtX9U.input-auto-suggest_is-active__6u9zp {
  border-color: #005ea8;
}
.input-auto-suggest_InputAutoSuggest-inputBorder__rtX9U.input-auto-suggest_is-error__RpDQw {
  border-color: #c82d2d;
}
.input-auto-suggest_InputAutoSuggest-inputBorder__rtX9U.input-auto-suggest_is-disabled__LCahn {
  background-color: #f4f4f4;
  border-color: #dcdcdc;
}
.input-auto-suggest_InputAutoSuggest-inputBorder__rtX9U.input-auto-suggest_is-disabled__LCahn:before {
  color: #666666;
}
.input-auto-suggest_InputAutoSuggest-wrapper__fS4T- {
  display: flex;
  font-family: Arial, Helvetica, Sans-Serif;
  flex-direction: column;
}
.input-auto-suggest_InputAutoSuggest-questionMark__vqRGL {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 16px;
  fill: #999999;
}
.input-auto-suggest_InputAutoSuggest-error__EHpaM {
  font-family: Arial, Helvetica, Sans-Serif;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 14px;
}
.input-auto-suggest-modal_InputFieldOccupation-row__M15zU {
  position: relative;
  width: 100%;
}
.input-auto-suggest-modal_InputFieldOccupation-row__M15zU input {
  width: calc(100% - 20px);
}
.input-auto-suggest-modal_InputFieldOccupation-occupationOverlay__laHBK {
  background: transparent;
  box-shadow: none;
  padding: 10px 10px 0 10px;
  border: none;
  width: 100%;
  padding: 0px;
  margin-top: 30px;
}
.theo_Theo-featured-text__-yYPa {
  font-size: 24px;
  line-height: 28px;
}
.theo_Theo-container__FgR7B {
  display: flex;
  margin-top: 16px;
  gap: 12px;
}
@media screen and (max-width: 320px) {
  .theo_Theo-container__FgR7B {
    gap: 0px;
  }
}
.theo_Theo-primary-text__TLHbB {
  font-family: Arial;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
}
.theo_Theo-svg__nwnpF {
  width: 87px;
  height: 84px;
  flex-shrink: 0;
}
.theo_Theo-right__4ny6g {
  margin-top: 0px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.theo_Theo-right__4ny6g .theo_Theo-primary-text__TLHbB {
  font-size: 24px;
  line-height: 28px;
}
.theo_Theo-right__4ny6g .theo_Theo-svg__nwnpF {
  margin-top: 6px;
}
.theo_Theo-secondary-text__cuUI4 {
  font-family: Arial;
  color: #333333;
  font-size: 16px;
  line-height: 18px;
  margin-top: 16px;
}
.theo_Theo-featured-secondary-text__FfiRq {
  font-size: 24px;
  line-height: 28px;
}
.chips_Chips-container__otXwd {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.chips_Chips-container__otXwd .chips_Chips-error__q7-WO {
  font-family: Arial, Helvetica, Sans-Serif;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: #c82d2d;
}
.chips_Chips__AAVsq {
  height: 29px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border: 1px solid #dcdcdc;
  color: #333333;
  background: #ffffff;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.chips_Chips__AAVsq.chips_Chips-selected__ziV1t {
  background: #ecf7fd;
  border: 1px solid #005ea8;
}
@keyframes cta-button_spinAround__DneWv {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.cta-button_CtaButton__DZHT0 {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #0271c2;
  padding: 10px;
  color: #ffffff;
  border-radius: 3px;
  font-size: 20px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: 1px solid #0271c2;
  pointer-events: auto;
  text-align: center;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
}
.cta-button_CtaButton__DZHT0:focus,
.cta-button_CtaButton__DZHT0:active,
.cta-button_CtaButton__DZHT0:active:focus,
.cta-button_CtaButton__DZHT0:hover {
  outline: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26);
  text-decoration: none;
}
.cta-button_CtaButton__DZHT0:hover {
  background-color: #015fa4;
}
.cta-button_CtaButton__DZHT0:disabled,
.cta-button_CtaButton__DZHT0.cta-button_is-disabled__a1pAT {
  background-color: #015fa4;
  border: 1px solid #015fa4;
}
.cta-button_CtaButton__DZHT0:disabled:active,
.cta-button_CtaButton__DZHT0.cta-button_is-disabled__a1pAT:active,
.cta-button_CtaButton__DZHT0:disabled:active:focus,
.cta-button_CtaButton__DZHT0.cta-button_is-disabled__a1pAT:active:focus,
.cta-button_CtaButton__DZHT0:disabled:hover,
.cta-button_CtaButton__DZHT0.cta-button_is-disabled__a1pAT:hover,
.cta-button_CtaButton__DZHT0:disabled:focus,
.cta-button_CtaButton__DZHT0.cta-button_is-disabled__a1pAT:focus {
  text-decoration: none;
}
.cta-button_CtaButton__DZHT0.cta-button_is-inverted__d4oHA {
  background-color: #ffffff;
  color: #005ea8;
  border: 1px solid #005ea8;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}
.cta-button_CtaButton__DZHT0.cta-button_is-inverted__d4oHA:active,
.cta-button_CtaButton__DZHT0.cta-button_is-inverted__d4oHA:active:focus,
.cta-button_CtaButton__DZHT0.cta-button_is-inverted__d4oHA:hover,
.cta-button_CtaButton__DZHT0.cta-button_is-inverted__d4oHA:focus {
  outline: none;
  text-decoration: none;
  color: #005ea8;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #005ea8;
  background-color: #fafafa;
}
.cta-button_CtaButton__DZHT0.cta-button_is-secondary__bct9u {
  border: 1px solid #cccccc;
  color: #666666;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.cta-button_CtaButton__DZHT0.cta-button_is-secondary__bct9u:active,
.cta-button_CtaButton__DZHT0.cta-button_is-secondary__bct9u:active:focus,
.cta-button_CtaButton__DZHT0.cta-button_is-secondary__bct9u:hover,
.cta-button_CtaButton__DZHT0.cta-button_is-secondary__bct9u:focus {
  background-color: #fafafa;
}
.cta-button_CtaButton__DZHT0 .cta-button_CtaButton-label__6llY6 {
  position: relative;
}
.cta-button_CtaButton__DZHT0 .cta-button_CtaButton-navigationSpinner__qpuaw {
  display: inline-block;
  position: absolute;
  margin-left: 10px;
  height: 24px;
  width: 24px;
  animation: cta-button_spinAround__DneWv 0.7s infinite linear;
}
.cta-button_CtaButton__DZHT0 .cta-button_CtaButton-navigationSpinner__qpuaw:after {
  height: 24px;
  width: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-width: 4px;
  content: "";
  border-color: #ffffff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
  top: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 50%;
  border-style: solid;
  border-radius: 50%;
}
.progress-bar_ProgressBar__F-AOn {
  position: relative;
  min-height: 5px;
  border-radius: 10px;
  background-color: #b4b4b4;
}
.progress-bar_ProgressBar-filled__dqDJW {
  position: absolute;
  border-radius: 10px;
  background-color: #005ea8;
  top: 0;
  left: 0;
  height: 100%;
}
.radio-button_RadioButton__GvaXC {
  border: none;
  background-color: transparent;
  padding: 0 16px;
  margin: 8px 0;
  width: 50%;
  color: #333333;
  stroke: #b4b4b4;
  fill: #b4b4b4;
}
.radio-button_RadioButton__GvaXC:not(:first-child) {
  border-left: 1px solid #dcdcdc;
}
.radio-button_RadioButton__GvaXC:hover {
  stroke: #333333;
  fill: #333333;
}
.radio-button_RadioButton__GvaXC.radio-button_is-selected__clqck {
  stroke: #005ea8;
  fill: #005ea8;
}
.radio-button_RadioButton__GvaXC.radio-button_is-disabled__-C3au {
  stroke: #999999;
  fill: #999999;
  color: #666666;
}
.radio-button_RadioButton__GvaXC.radio-button_is-error__NWz9r {
  stroke: #c82d2d;
  fill: #c82d2d;
}
.radio-button_RadioButton-contentWrapper__Dh72d {
  display: flex;
  align-items: center;
  font-family: Verdana;
  font-size: 18px;
}
.radio-button_RadioButton-svgIcon__VXOsW {
  margin: -4px;
  height: 28px;
  width: 28px;
  margin-right: 6px;
  color: #b4b4b4;
}
.radio-button-group_RadioButtonGroup__MIZNj {
  height: 58px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #dcdcdc;
  display: flex;
  justify-content: space-around;
}
.radio-button-group_RadioButtonGroup__MIZNj:hover {
  border-color: #333333;
}
.radio-button-group_RadioButtonGroup__MIZNj.radio-button-group_is-disabled__58ENC {
  background-color: #fafafa;
  border-color: #dcdcdc;
}
.radio-button-group_RadioButtonGroup__MIZNj.radio-button-group_is-error__TjNNA {
  border-color: #c82d2d;
}
.radio-button-group_RadioButtonGroup__MIZNj.radio-button-group_is-selected__F8D-3 {
  border-bottom: 2px solid #7ab51d;
}
.radio-button-group_RadioButtonGroup-error__5EWv8 {
  color: #c82d2d;
  font-family: Verdana;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
}
.select-box_SelectBox__tdR1H {
  font-family: Arial, Helvetica, Sans-Serif;
  position: relative;
  height: 58px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}
.select-box_SelectBox__tdR1H.select-box_is-active__JUttH {
  border-color: #005ea8;
}
.select-box_SelectBox__tdR1H.select-box_is-error__StMYG {
  border-color: #c82d2d;
}
.select-box_SelectBox__tdR1H.select-box_is-disabled__9qKfA {
  border-color: #dcdcdc;
  background-color: #f4f4f4;
}
.select-box_SelectBox-selectArrow__uNf8P {
  width: 15px;
  height: 9px;
  position: absolute;
  right: 16px;
  top: 25px;
  fill: #999999;
  pointer-events: none;
}
.select-box_SelectBox-selectArrow__uNf8P.select-box_has-modal__uc5yG {
  right: 56px;
}
.select-box_SelectBox-questionMark__JbpyJ {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 18px;
  fill: #999999;
}
.select-box_SelectBox-error__1h1MJ {
  width: 100%;
  font-family: Arial, Helvetica, Sans-Serif;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 14px;
}
.select-box_SelectBox-label__dqPdS {
  color: #666666;
  font-size: 13px;
  line-height: 13px;
  padding-top: 9px;
  padding-bottom: 3px;
}
.select-box_SelectBox-label__dqPdS.select-box_is-active__JUttH {
  color: #005ea8;
}
.select-box_SelectBox-label__dqPdS.select-box_is-error__StMYG {
  color: #c82d2d;
}
.select-box_SelectBox-label__dqPdS.select-box_is-disabled__9qKfA {
  color: #999999;
}
.select-box_SelectBox-input__m3F1h {
  font-size: 18px;
  font-family: Arial, Helvetica, Sans-Serif;
  border: none;
  padding: 0 45px 0 0px;
  outline: none;
  appearance: none;
  color: #333333;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 19px;
}
.select-box_SelectBox-input__m3F1h.select-box_is-empty__5f6wo {
  color: #999999;
}
.select-box_SelectBox-input__m3F1h option[value=""] {
  color: #999999;
}
.select-box_SelectBox-input__m3F1h option:not([value=""]) {
  color: #333333;
}
.select-box_SelectBox-input__m3F1h.select-box_is-disabled__9qKfA {
  color: #666666;
}
.select-box_SelectBox-input__m3F1h.select-box_has-modal__uc5yG {
  width: calc(100% - 42px);
}
.select-box_without-label__K8sU2 {
  padding-top: 1px;
}
.back-button_BackButton__O7-O6 {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.back-button_BackButton__O7-O6 span {
  color: #005ea8;
  font-family: Arial;
  font-weight: 700;
  font-size: 12px;
}
.back-button_BackButton__O7-O6 svg {
  width: 15px;
  height: 10px;
  fill: #005ea8;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneNumber__xYDXI {
  font-size: 18px;
  color: #005ea8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneNumber__xYDXI.consultant-carousel-item_lite__lcRDy {
  font-size: 14px;
  margin-top: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-container__1baXZ {
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
}
.consultant-carousel-item_ConsultantCarouselItem-container__1baXZ.consultant-carousel-item_is-lite__5bVCD {
  display: flex;
  padding: 10px 30px;
  align-items: center;
}
.consultant-carousel-item_ConsultantCarousel-headline__S6-lj {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.consultant-carousel-item_ConsultantCarouselItem-phoneSvg__6x9J4 {
  fill: #005ea8;
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-additionalText__elgdw {
  display: flex;
  align-items: flex-end;
  color: #005ea8;
  fill: #005ea8;
  font-size: 12px;
  width: 75px;
  justify-content: flex-end;
}
.consultant-carousel-item_ConsultantCarouselItem-expandCollapseArrow__gpHCX {
  width: 13px;
  height: 7px;
  margin-left: 5px;
  bottom: 6px;
  position: relative;
}
.consultant-carousel-item_ConsultantCarouselItem-consultantDetails__dodri {
  justify-content: center;
  margin-top: 0;
  flex: 7;
}
.consultant-carousel-item_ConsultantCarouselItem-consultantDetails__dodri.consultant-carousel-item_lite__lcRDy {
  margin: auto;
}
.consultant-carousel-item_ConsultantCarouselItem-infoQuote__odSE- {
  font-size: 12px;
  color: #999999;
}
.consultant-carousel-item_ConsultantCarouselItem-infoTitle__dLZ-1 {
  font-size: 14px;
  color: #333333;
}
.consultant-carousel-item_ConsultantCarouselItem-name__pqR3t {
  text-align: center;
  font-size: 16px;
  color: #333333;
}
.consultant-carousel-item_ConsultantCarouselItem-photo__GtyvT {
  border-radius: 100%;
  border: 1px solid #dcdcdc;
  margin: auto;
}
.consultant-carousel-item_ConsultantCarouselItem-companyName__D9exQ {
  text-align: center;
  font-size: 12px;
  color: #333333;
  margin-top: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-positionTitle__bFxbm {
  text-align: center;
  font-size: 12px;
  color: #333333;
  margin-top: 0;
}
.consultant-carousel-item_ConsultantCarouselItem-photoAndQuote__uzNDD {
  display: flex;
  margin: auto;
  width: 88%;
}
.consultant-carousel-item_ConsultantCarouselItem-photoAndConsultantInformation__IdekW {
  flex: 3;
}
.consultant-carousel-item_ConsultantCarouselItem-photoAndConsultantInformation__IdekW.consultant-carousel-item_lite__lcRDy {
  margin-top: -5px;
}
.consultant-carousel-item_ConsultantCarouselItem-photoContainer__CK59t {
  flex: 1;
  padding-right: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-quote__I6NTz {
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  background-color: #ecf7fd;
  color: #333333;
  height: 100%;
  flex: 3;
  margin-left: 5px;
  margin-right: 5px;
}
.consultant-carousel-item_ConsultantCarouselItem-arrow__7T-eb {
  display: block;
  position: relative;
  top: 28px;
  left: -24px;
  width: 11px;
  height: 0;
}
.consultant-carousel-item_ConsultantCarouselItem-arrow__7T-eb:after {
  content: " ";
  border-color: transparent #ecf7fd;
  border-image: none;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  width: 1px;
  position: absolute;
  margin-top: -22px;
}
.consultant-carousel-item_slick-slide__vxsLG {
  background-color: white;
}
/* Slider */
.consultant-carousel_slick-slider__GwImr {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.consultant-carousel_slick-list__bjrwI {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.consultant-carousel_slick-list__bjrwI:focus {
  outline: none;
}
.consultant-carousel_slick-list__bjrwI.consultant-carousel_dragging__Rknxy {
  cursor: pointer;
  cursor: hand;
}
.consultant-carousel_slick-slider__GwImr .consultant-carousel_slick-track__Q1czg,
.consultant-carousel_slick-slider__GwImr .consultant-carousel_slick-list__bjrwI {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.consultant-carousel_slick-track__Q1czg {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.consultant-carousel_slick-track__Q1czg:before,
.consultant-carousel_slick-track__Q1czg:after {
  content: "";
  display: table;
}
.consultant-carousel_slick-track__Q1czg:after {
  clear: both;
}
.consultant-carousel_slick-loading__v2dV0 .consultant-carousel_slick-track__Q1czg {
  visibility: hidden;
}
.consultant-carousel_slick-slide__wK-jD {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .consultant-carousel_slick-slide__wK-jD {
  float: right;
}
.consultant-carousel_slick-slide__wK-jD img {
  display: block;
}
.consultant-carousel_slick-slide__wK-jD.consultant-carousel_slick-loading__v2dV0 img {
  display: none;
}
.consultant-carousel_slick-slide__wK-jD.consultant-carousel_dragging__Rknxy img {
  pointer-events: none;
}
.consultant-carousel_slick-initialized__sxZBu .consultant-carousel_slick-slide__wK-jD {
  display: block;
}
.consultant-carousel_slick-loading__v2dV0 .consultant-carousel_slick-slide__wK-jD {
  visibility: hidden;
}
.consultant-carousel_slick-vertical__n8HGK .consultant-carousel_slick-slide__wK-jD {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.consultant-carousel_slick-arrow__Ipcmq.consultant-carousel_slick-hidden__LSBxk {
  display: none;
}
/* Slider */
.consultant-carousel_slick-loading__v2dV0 .consultant-carousel_slick-list__bjrwI {
  background: #fff url('slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.consultant-carousel_slick-prev__-xh9X,
.consultant-carousel_slick-next__fVSFh {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.consultant-carousel_slick-prev__-xh9X:hover,
.consultant-carousel_slick-next__fVSFh:hover,
.consultant-carousel_slick-prev__-xh9X:focus,
.consultant-carousel_slick-next__fVSFh:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.consultant-carousel_slick-prev__-xh9X:hover:before,
.consultant-carousel_slick-next__fVSFh:hover:before,
.consultant-carousel_slick-prev__-xh9X:focus:before,
.consultant-carousel_slick-next__fVSFh:focus:before {
  opacity: 1;
}
.consultant-carousel_slick-prev__-xh9X.consultant-carousel_slick-disabled__T4KT0:before,
.consultant-carousel_slick-next__fVSFh.consultant-carousel_slick-disabled__T4KT0:before {
  opacity: 0.25;
}
.consultant-carousel_slick-prev__-xh9X:before,
.consultant-carousel_slick-next__fVSFh:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('slick-carousel/slick/fonts/slick.eot');
  src: url('slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('slick-carousel/slick/fonts/slick.woff') format('woff'), url('slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
.consultant-carousel_slick-prev__-xh9X {
  left: -25px;
}
[dir="rtl"] .consultant-carousel_slick-prev__-xh9X {
  left: auto;
  right: -25px;
}
.consultant-carousel_slick-prev__-xh9X:before {
  content: "←";
}
[dir="rtl"] .consultant-carousel_slick-prev__-xh9X:before {
  content: "→";
}
.consultant-carousel_slick-next__fVSFh {
  right: -25px;
}
[dir="rtl"] .consultant-carousel_slick-next__fVSFh {
  left: -25px;
  right: auto;
}
.consultant-carousel_slick-next__fVSFh:before {
  content: "→";
}
[dir="rtl"] .consultant-carousel_slick-next__fVSFh:before {
  content: "←";
}
/* Dots */
.consultant-carousel_slick-dotted__PTRLB .consultant-carousel_slick-slider__GwImr {
  margin-bottom: 30px;
}
.consultant-carousel_slick-dots__LcgZn {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.consultant-carousel_slick-dots__LcgZn li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.consultant-carousel_slick-dots__LcgZn li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.consultant-carousel_slick-dots__LcgZn li button:hover,
.consultant-carousel_slick-dots__LcgZn li button:focus {
  outline: none;
}
.consultant-carousel_slick-dots__LcgZn li button:hover:before,
.consultant-carousel_slick-dots__LcgZn li button:focus:before {
  opacity: 1;
}
.consultant-carousel_slick-dots__LcgZn li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.consultant-carousel_slick-dots__LcgZn li.consultant-carousel_slick-active__Aj9Uh button:before {
  color: black;
  opacity: 0.75;
}
.consultant-carousel_ConsultantCarousel-wrapper__q6c4y {
  margin-top: 10px;
  border: 1px solid #dcdcdc;
  line-height: 1.42857143;
  font-family: Arial, Helvetica, Sans-Serif;
}
.consultant-carousel_ConsultantCarousel-wrapper__q6c4y.consultant-carousel_is-comparison__qZEfL {
  min-width: 340px;
  margin-left: 40px;
}
.consultant-carousel_ConsultantCarousel-wrapper__q6c4y.consultant-carousel_is-lite__5ayVC {
  border: none;
}
.consultant-carousel_ConsultantCarousel-wrapper__q6c4y.consultant-carousel_is-lite__5ayVC.consultant-carousel_is-comparison__qZEfL {
  margin-left: 0;
  min-width: 0;
}
.consultant-carousel_slick-prev__-xh9X,
.consultant-carousel_slick-next__fVSFh {
  z-index: 1;
  fill: #dcdcdc;
  height: 36px;
  width: 10px;
}
.consultant-carousel_slick-prev__-xh9X:before,
.consultant-carousel_slick-next__fVSFh:before {
  content: '';
  position: absolute;
  top: -30px;
  left: -15px;
  right: -15px;
  bottom: -30px;
}
.consultant-carousel_slick-next__fVSFh {
  right: 12px;
}
.consultant-carousel_slick-prev__-xh9X {
  left: 10px;
}
.consultant-carousel_nextArrow__XAQAl,
.consultant-carousel_prevArrow__U9e-u {
  height: 36px;
  width: 10px;
}
.calendar-carousel-item_slick-slide__K-hm8 {
  background-color: #ffffff;
}
.calendar-carousel-item_CalendarCarouselItem-box__l0kRr {
  margin: auto;
  width: 53px;
  height: 48px;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.calendar-carousel-item_CalendarCarouselItem-name__zsmAP {
  font-weight: 700;
  font-size: 14px;
}
.calendar-carousel-item_CalendarCarouselItem-date__nwgD2 {
  font-size: 14px;
  text-align: center;
}
.calendar-carousel-item_CalendarCarouselItem-container__kI5Q2 {
  padding-top: 5px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.calendar-carousel-item_CalendarCarouselItem-box-disable__mb9aI {
  background-color: #f4f4f4;
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
  box-shadow: unset;
}
.calendar-carousel-item_CalendarCarouselItem-active__X-D2H {
  background-color: #005ea8;
  color: #ffffff;
  border: 1px solid #005ea8;
}
/* Slider */
.calendar-carousel-arrow-button_slick-slider__z9DGR {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.calendar-carousel-arrow-button_slick-list__1C51i {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.calendar-carousel-arrow-button_slick-list__1C51i:focus {
  outline: none;
}
.calendar-carousel-arrow-button_slick-list__1C51i.calendar-carousel-arrow-button_dragging__2Z8TE {
  cursor: pointer;
  cursor: hand;
}
.calendar-carousel-arrow-button_slick-slider__z9DGR .calendar-carousel-arrow-button_slick-track__DwBf1,
.calendar-carousel-arrow-button_slick-slider__z9DGR .calendar-carousel-arrow-button_slick-list__1C51i {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.calendar-carousel-arrow-button_slick-track__DwBf1 {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.calendar-carousel-arrow-button_slick-track__DwBf1:before,
.calendar-carousel-arrow-button_slick-track__DwBf1:after {
  content: "";
  display: table;
}
.calendar-carousel-arrow-button_slick-track__DwBf1:after {
  clear: both;
}
.calendar-carousel-arrow-button_slick-loading__c8B-B .calendar-carousel-arrow-button_slick-track__DwBf1 {
  visibility: hidden;
}
.calendar-carousel-arrow-button_slick-slide__2Po8J {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .calendar-carousel-arrow-button_slick-slide__2Po8J {
  float: right;
}
.calendar-carousel-arrow-button_slick-slide__2Po8J img {
  display: block;
}
.calendar-carousel-arrow-button_slick-slide__2Po8J.calendar-carousel-arrow-button_slick-loading__c8B-B img {
  display: none;
}
.calendar-carousel-arrow-button_slick-slide__2Po8J.calendar-carousel-arrow-button_dragging__2Z8TE img {
  pointer-events: none;
}
.calendar-carousel-arrow-button_slick-initialized__X7eYI .calendar-carousel-arrow-button_slick-slide__2Po8J {
  display: block;
}
.calendar-carousel-arrow-button_slick-loading__c8B-B .calendar-carousel-arrow-button_slick-slide__2Po8J {
  visibility: hidden;
}
.calendar-carousel-arrow-button_slick-vertical__rI8Rg .calendar-carousel-arrow-button_slick-slide__2Po8J {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.calendar-carousel-arrow-button_slick-arrow__VbDzI.calendar-carousel-arrow-button_slick-hidden__z1Kvw {
  display: none;
}
/* Slider */
.calendar-carousel-arrow-button_slick-loading__c8B-B .calendar-carousel-arrow-button_slick-list__1C51i {
  background: #fff url('slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.calendar-carousel-arrow-button_slick-prev__zU-BE,
.calendar-carousel-arrow-button_slick-next__2jvt2 {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE:hover,
.calendar-carousel-arrow-button_slick-next__2jvt2:hover,
.calendar-carousel-arrow-button_slick-prev__zU-BE:focus,
.calendar-carousel-arrow-button_slick-next__2jvt2:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE:hover:before,
.calendar-carousel-arrow-button_slick-next__2jvt2:hover:before,
.calendar-carousel-arrow-button_slick-prev__zU-BE:focus:before,
.calendar-carousel-arrow-button_slick-next__2jvt2:focus:before {
  opacity: 1;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE.calendar-carousel-arrow-button_slick-disabled__1hqTg:before,
.calendar-carousel-arrow-button_slick-next__2jvt2.calendar-carousel-arrow-button_slick-disabled__1hqTg:before {
  opacity: 0.25;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE:before,
.calendar-carousel-arrow-button_slick-next__2jvt2:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('slick-carousel/slick/fonts/slick.eot');
  src: url('slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('slick-carousel/slick/fonts/slick.woff') format('woff'), url('slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
.calendar-carousel-arrow-button_slick-prev__zU-BE {
  left: -25px;
}
[dir="rtl"] .calendar-carousel-arrow-button_slick-prev__zU-BE {
  left: auto;
  right: -25px;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE:before {
  content: "←";
}
[dir="rtl"] .calendar-carousel-arrow-button_slick-prev__zU-BE:before {
  content: "→";
}
.calendar-carousel-arrow-button_slick-next__2jvt2 {
  right: -25px;
}
[dir="rtl"] .calendar-carousel-arrow-button_slick-next__2jvt2 {
  left: -25px;
  right: auto;
}
.calendar-carousel-arrow-button_slick-next__2jvt2:before {
  content: "→";
}
[dir="rtl"] .calendar-carousel-arrow-button_slick-next__2jvt2:before {
  content: "←";
}
/* Dots */
.calendar-carousel-arrow-button_slick-dotted__LYy-C .calendar-carousel-arrow-button_slick-slider__z9DGR {
  margin-bottom: 30px;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button:hover,
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button:focus {
  outline: none;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button:hover:before,
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button:focus:before {
  opacity: 1;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.calendar-carousel-arrow-button_slick-dots__bdMc3 li.calendar-carousel-arrow-button_slick-active__fD44x button:before {
  color: black;
  opacity: 0.75;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE,
.calendar-carousel-arrow-button_slick-next__2jvt2 {
  z-index: 1;
  fill: #dcdcdc;
  height: 36px;
  width: 16px;
  top: 46%;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE:before,
.calendar-carousel-arrow-button_slick-next__2jvt2:before {
  content: '';
  position: absolute;
  top: -30px;
  left: -15px;
  right: -15px;
  bottom: -30px;
}
.calendar-carousel-arrow-button_slick-next__2jvt2 {
  right: 5px;
}
.calendar-carousel-arrow-button_slick-next__2jvt2 svg {
  width: 16px;
  height: 33px;
  fill: #005ea8;
  flex-shrink: 0;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE {
  left: 6px;
}
.calendar-carousel-arrow-button_slick-prev__zU-BE svg {
  transform: rotate(180deg);
}
.calendar-carousel-arrow-button_nextArrow__XuRV6,
.calendar-carousel-arrow-button_prevArrow__VKgdQ {
  height: 36px;
  width: 16px;
}
.calendar-carousel_CalendarCarousel-wrapper__vOm92 {
  margin-top: 10px;
  line-height: 1.42857143;
  font-family: Arial, Helvetica, Sans-Serif;
}
.calendar-carousel_CalendarCarousel-date__NV6Qq {
  background-color: #ffbb1c;
  border-radius: 3px;
  padding-top: 10px;
}
.calendar-carousel_CalendarCarousel-calendar__iIpBO {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.calendar-carousel_CalendarCarousel-header__sIoFm {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 16px;
}
.calendar-carousel_CalendarCarousel-slider__J7RS5 {
  padding-left: 20px;
  padding-right: 20px;
}
.calendar-carousel_CalendarCarousel-simple-slider__H1Pm9 {
  padding-left: 10px;
  padding-right: 10px;
}
.calendar-timeslots_CalendarTimeslots-wrapper__wqtqi {
  background-color: #ffbb1c;
  margin-top: 5px;
  padding: 10px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
}
.calendar-timeslots_CalendarTimeslots-container__o-8WA {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
}
.calendar-timeslots_CalendarTimeslots-slot__sEdLt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75px;
  height: 40px;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  margin-bottom: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 14px;
}
.calendar-timeslots_CalendarTimeslots-active__6ep9J {
  background-color: #005ea8 !important;
  color: #ffffff;
  border: 1px solid #005ea8 !important;
}
.calendar-timeslots_CalendarTimeslots-header__Zsnzn {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}
.calendar-timeslots_CalendarTimeslots-header__Zsnzn svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  flex-shrink: 0;
}
.calendar-timeslots_CalendarTimeslots-substitute__fXMFo {
  display: flex;
  border-bottom: 1px solid;
  margin-bottom: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
}
.calendar-timeslots_CalendarTimeslots-substitute__fXMFo span {
  font-size: 14px;
  margin-right: 5px;
}
.calendar-timeslots_CalendarTimeslots-substitute__fXMFo img,
.calendar-timeslots_CalendarTimeslots-substitute__fXMFo svg {
  flex-shrink: 0;
}
.calendar-timeslots_CalendarTimeslots-disable__-Xwzq {
  background-color: #f4f4f4;
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
  box-shadow: unset;
}
.banner_Banner__OOTZ6 {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 14px;
  font-size: 14px;
  background-color: rgba(6, 55, 115, 0.15);
  width: fit-content;
}
.banner_Banner__OOTZ6.banner_is-small__xv6eQ {
  font-size: 9px;
  text-align: center;
}
.banner_Banner__OOTZ6 .banner_Banner-span__A0ZQ7 {
  font-weight: 600;
  color: #063773;
  word-break: normal;
}
.banner_Banner__OOTZ6 .banner_Banner-svg-container__WKcjt {
  display: flex;
}
.banner_Banner__OOTZ6 .banner_Banner-svg__5aMjm {
  fill: #063773;
  margin-left: 8px;
  pointer-events: auto;
  cursor: help;
  width: 12px;
  flex-shrink: 0;
}
.banner_Banner__OOTZ6.banner_is-small-screen__gRjGt {
  padding-left: 5px;
  padding-right: 5px;
}
.banner_Banner__OOTZ6.banner_is-deadline__QAxsR {
  background-color: rgba(240, 124, 0, 0.15);
}
.banner_Banner__OOTZ6.banner_is-deadline__QAxsR .banner_Banner-span__A0ZQ7 {
  color: #f07c00;
}
.banner_Banner__OOTZ6.banner_is-deadline__QAxsR .banner_Banner-svg__5aMjm {
  fill: #f07c00;
}
.banner_Banner__OOTZ6.banner_is-green__DDJRV {
  background-color: rgba(122, 181, 29, 0.15);
}
.banner_Banner__OOTZ6.banner_is-green__DDJRV .banner_Banner-span__A0ZQ7 {
  color: #7ab51d;
}
.banner_Banner__OOTZ6.banner_is-green__DDJRV .banner_Banner-svg__5aMjm {
  fill: #7ab51d;
}
.savings-campaign-banner_SavingsCampaignBanner__S0ywD {
  display: flex;
  align-items: center;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__uaKUj {
  margin-left: 10px;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__uaKUj svg {
  width: 36px;
  height: 35px;
}
.savings-campaign-banner_SavingsCampaignBanner-pig__uaKUj svg path {
  fill: #063773;
}
.savings-campaign-banner_SavingsCampaignBanner-info-text__A-0vP {
  width: 185px;
  display: flex;
  align-items: center;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__avsAM {
  height: 56px;
  margin-right: -29px;
  font-weight: 600;
  position: relative;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__avsAM svg {
  width: 56px;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__avsAM svg circle {
  fill: #fafafa;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown__avsAM svg path {
  fill: #063773;
}
.savings-campaign-banner_SavingsCampaignBanner-text__EbYL- {
  color: #063773;
  font-family: Arial;
  font-size: 7px;
  line-height: 5px;
}
.savings-campaign-banner_SavingsCampaignBanner-number__EI60H {
  color: #febb00;
  font-family: Arial;
  font-size: 18px;
  line-height: 19px;
  font-weight: 900;
}
.savings-campaign-banner_SavingsCampaignBanner-countdown-text__o4pRZ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.savings-campaign-banner_SavingsCampaignBanner-info__2bv1a {
  background: #febb00;
  width: 670px;
  height: 56px;
  display: flex;
  align-items: center;
  font-family: Arial;
  font-size: 16px;
  color: #063773;
  line-height: 18px;
  justify-content: space-between;
  margin-right: 27px;
}
.savings-campaign-banner_SavingsCampaignBanner-info__2bv1a .savings-campaign-banner_SavingsCampaignBanner-svg-info__H5yQf {
  fill: #063773;
  width: 16px;
  margin-left: 9px;
}
.savings-campaign-banner_SavingsCampaignBanner-white__fJEBD svg circle {
  fill: #ffffff;
}
.savings-campaign-banner_SavingsCampaignBanner-tooltipHeadline__SDFog {
  color: #333333;
  font-weight: bold;
}
.savings-campaign-banner_SavingsCampaignBanner-occupationOverlay__9XkW5 {
  box-shadow: none;
  padding: 10px 10px 0 10px;
  border: none;
  width: 100%;
  padding: 0px;
  margin-top: 30px;
}
.result-zero-banner_ResultZeroHeader-headline__NyTzq {
  font-weight: 700;
}
.radio-button_RadioButton__zxOg1 {
  border: 1px solid #b4b4b4;
  appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: relative;
}
.radio-button_RadioButton__zxOg1:hover {
  border: 1px solid #005ea8;
  cursor: pointer;
}
.radio-button_RadioButton__zxOg1:checked {
  border: 1px solid #005ea8;
}
.radio-button_RadioButton__zxOg1:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #005ea8;
  transform: translate(-50%, -50%);
}
.radio-button_RadioButton__zxOg1:disabled {
  background: #cdcdcd;
}
.radio-button_RadioButton__zxOg1:disabled::before {
  background-color: #999999;
}
.radio-button_RadioButton__zxOg1:disabled:hover {
  border: 1px solid #b4b4b4;
}
.radio-button_RadioButton__zxOg1:disabled:hover:checked {
  border: 1px solid #999999;
}
.radio-button_RadioButton__zxOg1:disabled:checked {
  border: 1px solid #999999;
}
.points-gratification-bar_C24Points__-keGC {
  background-color: #c1e3e3;
  color: #005c61;
}
.points-gratification-bar_C24Points-wrapper__XuZOf {
  display: flex;
}
.points-gratification-bar_C24Points-text__nPBRe {
  font-size: 14px;
  padding: 5px 0;
  width: 90%;
}
.points-gratification-bar_C24Points-pointsIcon__TcDSb {
  flex-shrink: 0;
  width: 20px;
  height: 14px;
  fill: #005c61;
  margin: auto 10px;
}
.points-gratification-bar_C24Points-questionLogo__-Ay5G {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  fill: #005c61;
  margin: auto 10px;
}
.points-gratification-bar_C24Points-helpText__W4k3z {
  position: relative;
  border: 1px solid #005ea8;
  padding: 14px;
  font-size: 14px;
  color: #333;
  background: white;
}
.points-gratification-bar_C24Points-helpText__W4k3z:after,
.points-gratification-bar_C24Points-helpText__W4k3z:before {
  position: absolute;
  bottom: 100%;
  right: 5px;
  content: " ";
  border: solid transparent;
}
.points-gratification-bar_C24Points-bottomArrow__xYhAF {
  border-top: 1px solid #005ea8;
  margin: 10px auto -7px;
  text-align: center;
  padding-top: 13px;
  height: 25px;
  width: 100%;
}
.points-gratification-bar_C24Points-helpText__W4k3z:after {
  border: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  margin-right: 1px;
}
.points-gratification-bar_C24Points-helpText__W4k3z:before {
  border: 7px solid transparent;
  border-bottom: 8px solid #005ea8;
  font-size: 14px;
}
.points-gratification-bar_C24Points-helpTextWrapper__1HQgc,
.points-gratification-bar_C24Points-textLogoWrapper__vkKmH {
  height: fit-content;
}
.points-gratification-bar_C24Points-helpTextWrapper__1HQgc {
  padding: 10px;
}
.points-gratification-bar_C24PointsInfo-tooltip-pointsContent__2G0j8 {
  display: flex;
  flex-direction: column;
}
.points-gratification-bar-module_PointsInfo__jagsr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -13px;
  margin-bottom: 20px;
  height: 28px;
  width: 100%;
  font-family: Verdana;
  font-size: 11px;
  font-weight: bold;
  background-color: #c1e3e3;
  color: #005c61;
}
.points-gratification-bar-module_PointsInfo-wrapper__DQkr8 {
  display: flex;
  width: 100%;
  margin-left: 50px;
}
@media (max-width: 1280px) {
  .points-gratification-bar-module_PointsInfo-wrapper__DQkr8 {
    margin-left: 15px;
  }
}
.points-gratification-bar-module_PointsInfo-pointsIcon__jywHK {
  flex-shrink: 0;
  width: 17px;
  height: 12px;
  fill: #005c61;
  margin-top: 2px;
  margin-right: 10px;
}
.points-gratification-bar-module_PointsInfo-questionIcon__PQWvZ {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: #005c61;
  margin-left: 10px;
}
.points-gratification-bar-module_PointsInfo-questionIcon__PQWvZ.points-gratification-bar-module_is-mobile__kqyrs {
  cursor: pointer;
}
.points-gratification-bar-module_PointsInfo-tooltip__pPHlL {
  padding: 0px !important;
  pointer-events: auto !important;
  z-index: 1000 !important;
}
.points-gratification-bar-module_PointsInfo-tooltipContent__JRp9F {
  display: flex;
  align-items: flex-start;
}
.points-gratification-bar-module_PointsInfo-tooltipText__qODch {
  margin: 15px 0 15px 15px;
  flex-basis: 100%;
  line-height: 1.3;
}
.points-gratification-bar-module_PointsInfo-tooltipCloseIcon__JczmI {
  margin-top: 5px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  fill: #DCDCDC;
  cursor: pointer;
}
.points-gratification-bar-mobile-module_C24Points__3IX0G {
  background-color: #c1e3e3;
  color: #005c61;
}
.points-gratification-bar-mobile-module_C24Points-wrapper__Fls8q {
  display: flex;
}
.points-gratification-bar-mobile-module_C24Points-text__Bwr2T {
  font-size: 14px;
  padding: 5px 0;
  width: 90%;
}
.points-gratification-bar-mobile-module_C24Points-pointsIcon__VUa-m {
  flex-shrink: 0;
  width: 20px;
  height: 14px;
  fill: #005c61;
  margin: auto 10px;
}
.points-gratification-bar-mobile-module_C24Points-questionLogo__P4eLL {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  fill: #005c61;
  margin: auto 10px;
}
.points-gratification-bar-mobile-module_C24Points-helpText__MJM6q {
  position: relative;
  border: 1px solid #005ea8;
  padding: 14px;
  font-size: 14px;
  color: #333;
  background: white;
}
.points-gratification-bar-mobile-module_C24Points-helpText__MJM6q:after,
.points-gratification-bar-mobile-module_C24Points-helpText__MJM6q:before {
  position: absolute;
  bottom: 100%;
  right: 5px;
  content: " ";
  border: solid transparent;
}
.points-gratification-bar-mobile-module_C24Points-bottomArrow__2SnaS {
  border-top: 1px solid #005ea8;
  margin: 10px auto -7px;
  text-align: center;
  padding-top: 13px;
  height: 25px;
  width: 100%;
}
.points-gratification-bar-mobile-module_C24Points-helpText__MJM6q:after {
  border: 6px solid transparent;
  border-bottom: 6px solid #FFFFFF;
  margin-right: 1px;
}
.points-gratification-bar-mobile-module_C24Points-helpText__MJM6q:before {
  border: 7px solid transparent;
  border-bottom: 8px solid #005EA8;
  font-size: 14px;
}
.points-gratification-bar-mobile-module_C24Points-helpTextWrapper__-BG4A,
.points-gratification-bar-mobile-module_C24Points-textLogoWrapper__d23Os {
  height: fit-content;
}
.points-gratification-bar-mobile-module_C24Points-helpTextWrapper__-BG4A {
  padding: 10px;
}
.TooltipSelfAnchored {
  z-index: 100;
  padding: 10px 15px !important;
  border: 1px solid #005EA8 !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.45) !important;
  width: 320px;
  pointer-events: auto !important;
  font-size: 12px !important;
  line-height: 1.6 !important;
  opacity: 1 !important;
  color: #333333 !important;
}
.TooltipSelfAnchored.place-top:before {
  border-top: 8px solid #005EA8 !important;
}
.TooltipSelfAnchored.place-left:before {
  border-left: 8px solid #005EA8 !important;
}
.TooltipSelfAnchored.place-right:before {
  border-right: 8px solid #005EA8 !important;
}
.TooltipSelfAnchored.place-bottom:before {
  border-bottom: 8px solid #005EA8 !important;
}
.TooltipSelfAnchored.place-right:after {
  border-right: 6px solid #FFFFFF !important;
}
.TooltipSelfAnchored.place-left:after {
  border-left: 6px solid #FFFFFF !important;
}
.TooltipSelfAnchored.place-top:after {
  border-top: 6px solid #FFFFFF !important;
}
.TooltipSelfAnchored.place-bottom:after {
  border-bottom: 6px solid #FFFFFF !important;
}
.TooltipSelfAnchored-headline {
  color: #333333;
  font-weight: bold;
}
/* Slider */
.slick-loading .slick-list {
  background: #fff url('slick-carousel/slick/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('slick-carousel/slick/fonts/slick.eot');
  src: url('slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('slick-carousel/slick/fonts/slick.woff') format('woff'), url('slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}
.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
